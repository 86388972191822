import { CascadeSelects } from "../../lib/cascaded_selects"

const AdditionalCategory = ["Automobile", "Legal", "Medical", "Other Professional Negligence", "Premises Liability", "Product Liability", "Slander"]
const CaseSubtype1 = ["Initial Action", "Contempt", "Modification"]

const mapCaseSubtypes = (data) => {
  const result = {}

  data.forEach((item) => result[item] = {Title: item} )

  return result
}

const mapCaseSubtypesWithAdditionalCategories = (data, subdata) => {
  const result = {}
  const subResult = {}

  subdata.forEach((item) => subResult[item] = {Title: item} )
  data.forEach((item) => result[item] = {Title: item, Cascade: subResult} )

  return result
}

const CaseSubtypes = mapCaseSubtypes(CaseSubtype1);
const CascadeTextField = [{fieldType: "text"}];
const CaseSubtypesWithAdditionalCategories = mapCaseSubtypesWithAdditionalCategories(CaseSubtype1, AdditionalCategory )

const REFFERAL_SOURCE = {
  CascadeSelectorContainerId: "referal_source_container",
  CascadeSelectorName: "case[refferal_source]",
  CaseSelectorId: "case_refferal_source",
  CaseInputLabel: "Referral Source"
}

const CASE_CATEGORY = {
  CascadeSelectorContainerId: "case_category_container",
  CascadeSelectorName: "case[case_category]",
  CaseSelectorId: "case_case_category",
  CaseInputLabel: "Case Category"
}

const CASE_TYPE = {
  CascadeSelectorContainerId: "case_type_container",
  CascadeSelectorName: "case[case_type]",
  CaseSelectorId: "case_case_type",
  CaseInputLabel: "Case Type"
}

const CASE_SUBTYPE = {
  CascadeSelectorContainerId: "case_subtype_container",
  CascadeSelectorName: "case[case_subtype]",
  CaseSelectorId: "case_case_subtype",
  CaseInputLabel: "Case Subtype"
}

const CASE_ADDITIONAL_SUBTYPE = {
  CascadeSelectorContainerId: "case_additional_subtype_container",
  CascadeSelectorName: "case[case_additional_subtype]",
  CaseSelectorId: "case_case_additional_subtype",
  CaseInputLabel: "Case Additional Sub Type"
};

const CASE_ADDITIONAL_CATEGORY = {
  CascadeSelectorContainerId: "case_additional_category_container",
  CascadeSelectorName: "case[case_additional_category]",
  CaseSelectorId: "case_case_additional_category",
  CaseInputLabel: "CaseAdditionalCategory"
}

const CASCADE_LEVELS = [REFFERAL_SOURCE, CASE_CATEGORY, CASE_TYPE, CASE_SUBTYPE, CASE_ADDITIONAL_CATEGORY]

// Title: "Special Proceedings",
// AfterCascadeRender: renderCaseSubtypeTextbox,
const renderCaseSubtypeTextbox = (elemConfig) => {
  const rawHtml = `<div class="form-group select required case_refferal_source">
    <label class="select required" for="case_refferal_source">
      ${elemConfig.CaseInputLabel}
    </label>
    <input class="form-control string required" required="required" aria-required="true" name="${elemConfig.CascadeSelectorName}" id="${elemConfig.CaseSelectorId}" />
  </div>`

  const template = document.createElement('template');
  template.innerHTML = rawHtml.trim();

  const cascadeNodeContainer = document.getElementById(elemConfig.CascadeSelectorContainerId);

  const node = template.content.firstChild;

  while (cascadeNodeContainer.firstChild) {
    cascadeNodeContainer.removeChild(cascadeNodeContainer.firstChild);
  }

  cascadeNodeContainer.append(node)

  const textNode = document.getElementById(elemConfig.CaseSelectorId);;

  return textNode;
}

const Superior = {
  Title: "Superior",
  Cascade: {
    "Domestic Relations": {
      Title: "Domestic Relations",
      Cascade: {
        "Adoption": {
          Title: "Adoption"
          // Cascade: CaseSubtypes
        },
        "Alimony": {
          Title: "Alimony"
          // Cascade: CaseSubtypes
        },
        "Annulment": {
          Title: "Annulment"
          // Cascade: CaseSubtypes
        },
        "Contempt": {
          Title: "Contempt"
          // Cascade: CaseSubtypes
        },
        "Contempt – Monetary": {
          Title: "Contempt – Monetary"
          // Cascade: CaseSubtypes
        },
        "Contempt – Non-Monetary": {
          Title: "Contempt – Non-Monetary"
          // Cascade: CaseSubtypes
        },
        "Divorce/ Dissolution/ Separate Maintenance": {
          Title: "Divorce/ Dissolution/ Separate Maintenance"
          // Cascade: CaseSubtypes
        },
        "Domestication of Foreign Judgement": {
          Title: "Domestication of Foreign Judgement"
          // Cascade: CaseSubtypes
        },
        "Grandparent Visitation/Custody":{
          Title: "Grandparent Visitation/Custody"
        },
        "Legitimation": {
          Title: "Legitimation"
          // Cascade: CaseSubtypes
        },
        "Modification": {
          Title: "Modification"
          // Cascade: CaseSubtypes
        },
        "Paternity": {
          Title: "Paternity"
          // Cascade: CaseSubtypes
        },
        "Support-IV-D": {
          Title: "Support-IV-D"
          // Cascade: CaseSubtypes
        },
        "Support-Private (non- IV-D)": {
          Title: "Support-Private (non- IV-D)"
          // Cascade: CaseSubtypes
        },
      }
    },
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Appeals": {
          Title: "Appeals"
          // Cascade: CaseSubtypes
        },
        "Contracts": {
          Title: "Contracts"
          // Cascade: CaseSubtypes
        },
        "Damages": {
          Title: "Damages"
          // Cascade: CaseSubtypes
        },
        "Declaratory Judgement": {
          Title: "Declaratory Judgement"
          // Cascade: CaseSubtypes
        },
        "Dispossessory/Distress": {
          Title: "Dispossessory/Distress"
          // Cascade: CaseSubtypes
        },
        "Garnishment": {
          Title: "Garnishment"
          // Cascade: CaseSubtypes
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
          // Cascade: CaseSubtypes
        },
        "Other General Civil": {
          Title: "Other General Civil"
          // Cascade: CaseSubtypes
        },
        "Personal Injury": {
          Title: "Personal Injury"
          // Cascade: CaseSubtypes
        },
        "Personal Property": {
          Title: "Personal Property"
          // Cascade: CaseSubtypes
        },
        "Real Property": {
          Title: "Real Property"
          // Cascade: CaseSubtypes
        },
        "Tort": {
          Title: "Tort"
          // Cascade: CaseSubtypesWithAdditionalCategories
        },
      }
    }
  }
}

const State = {
  Title: "State",
  Cascade: {
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Traffic": {
          Title: "Traffic",
          // Cascade: CascadeTextField
        }
      }
    },
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Appeal": {
          Title: "Appeal"
          // Cascade: CaseSubtypes
        },
        "Contracts": {
          Title: "Contracts"
          // Cascade: CaseSubtypes
        },
        "Dispossessory/Distress": {
          Title: "Dispossessory/Distress"
          // Cascade: CaseSubtypes
        },
        "Garnishment": {
          Title: "Garnishment"
          // Cascade: CaseSubtypes
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
          // Cascade: CaseSubtypes
        },
        "Other General Civil": {
          Title: "Other General Civil"
          // Cascade: CaseSubtypes
        },
        "Tort": {
          Title: "Tort"
          // Cascade: CaseSubtypesWithAdditionalCategories
        },
      }
    },
  }
}

const Probate = {
  Title: "Probate",
  Cascade: {
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Criminal Citations": {
          Title: "Criminal Citations"
        },
        "Traffic": {
          Title: "Traffic"
        }
      }
    },
    "General Probate": {
      Title: "General Probate",
      Cascade: {
        "Conservatorship": {
          Title: "Conservatorship"
        },
        "Estates": {
          Title: "Estates"
        },
        "Guardianship": {
          Title: "Guardianship"
        },
        "Trusts": {
          Title: "Trusts"
        }
      }
    },
    "Mental Health": {
      Title: "Mental Health",
      Cascade: {
        "Involuntary Treatment": {
          Title: "Involuntary Treatment"
        },
        "Order to Apprehend": {
          Title: "Order to Apprehend"
        },
        "Other Mental Health": {
          Title: "Other Mental Health"
        }
      }
    }
  }
}

const Juvenile = {
  Title: "Juvenile",
  Cascade: {
    "Children in Need of Services": {
      Title: "Children in Need of Services"
    },
    "Class A felony": {
      Title: "Class A felony"
    },
    "Class B felony": {
      Title: "Class B felony"
    },
    "Delinquency": {
      Title: "Delinquency"
    },
    "Dependency": {
      Title: "Dependency"
    },
    "Emancipation": {
      Title: "Emancipation"
    },
    "Not Designated": {
      Title: "Not Designated"
    },
    "Special Proceedings": {
      Title: "Special Proceedings"
    },
    "Termination of Parental Rights": {
      Title: "Termination of Parental Rights"
    },
    "Traffic": {
      Title: "Traffic"
    }
  }
}


const CaseSubtype2 = ["Seller", "Buyer"]
const Prices = ["$0-$500", "$501-$1000", "$1001", "$5000", "$5000", "$10000", "$10001-$15000"]

const CaseSubtypesWithPrices = mapCaseSubtypesWithAdditionalCategories(CaseSubtype2, Prices )

const Magistrate = {
  Title: "Magistrate",
  Cascade: {
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Civil Claims": {
          Title: "Civil Claims"
          // Cascade: CaseSubtypesWithPrices
        },
        "Dispossessories and Distress": {
          Title: "Dispossessories and Distress"
        },
        "Foreclosures and Attachments": {
          Title: "Foreclosures and Attachments"
        },
        "Garnishments": {
          Title: "Garnishments"
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
        }
      }
    },
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Ordinance Violations": {
          Title: "Ordinance Violations",
        },
        "Misdemeanors": {
          Title: "Misdemeanors",
          // Cascade: CascadeTextField
        }
      }
    }
  }
}

const Municipal = {
  Title: "Municipal",
  Cascade: {
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Claims": {
          Title: "Claims"
          // Cascade: {
          //   "Seller": { Title: "Seller" },
          //   "Buyer": { Title: "Buyer" }
          // }
        },
        "Dispossessories and Distress": {
          Title: "Dispossessories and Distress"
        },
        "Garnishments": {
          Title: "Garnishments"
        },
        "Foreclosures and Attachments": {
          Title: "Foreclosures and Attachments"
        },
        "Non-Criminal Traffic Violations": {
          Title: "Non-Criminal Traffic Violations"
        }
      }
    },
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Traffic": {
          Title: "Traffic",
          // Cascade: CascadeTextField
        },
        "Misdemeanors": {
          Title: "Misdemeanors",
          // Cascade: CascadeTextField
        },
        "Parking Violation": {
          Title: "Parking Violation"
        },
        "Ordinance": {
          Title: "Ordinance"
        }
      }
    }
  }
}

const DistrictAttorney = {
  Title: "District Attorney"
}

const CivilCourt = {
  Title: "Civil Court"
}

const Community = {
  Title: "Community"
}

const renderCascade = (elemConfig, rootForm, valueItem="", elem_required) => {
  let inputField;

  const isTextField = elemConfig.Cascade && Array.isArray(elemConfig.Cascade) && elemConfig.Cascade[0].fieldType === "text";

  if(isTextField)
    inputField = `<input value="${valueItem}" type="text" class="required form-control" aria-required="true" name="${isTextField ? CASE_ADDITIONAL_SUBTYPE.CascadeSelectorName : elemConfig.CascadeSelectorName}" id="${isTextField ? CASE_ADDITIONAL_SUBTYPE.CaseSelectorId : elemConfig.CaseSelectorId}"/>`;
  else
    inputField = `<select class="select required form-control" aria-required="true" ${elem_required == 'required' ? 'required' : '' } name="${elemConfig.CascadeSelectorName}" id="${elemConfig.CaseSelectorId}" data-parsley-required-message="${elemConfig.CaseInputLabel} can't be blank">`;

  const rawHtml = `<div class="form-group select required case_refferal_source">
    <label class="select required" for="case_refferal_source">
      ${isTextField ? CASE_ADDITIONAL_SUBTYPE.CaseInputLabel : elemConfig.CaseInputLabel}
      ${elem_required == 'required' ? "<abbr title='required'>*</abbr>" : ''}
    </label>
    ${inputField}
    </select>
  </div>`

  const template = document.createElement('template');
  template.innerHTML = rawHtml.trim();

  const cascadeNodeContainer = rootForm ? rootForm.find(`#${elemConfig.CascadeSelectorContainerId}`)[0] : document.getElementById(elemConfig.CascadeSelectorContainerId);

  const node = template.content.firstChild;

  while (cascadeNodeContainer.firstChild) {
    cascadeNodeContainer.removeChild(cascadeNodeContainer.firstChild);
  }

  cascadeNodeContainer.append(node)

  const selectNode = rootForm ? rootForm.find(`#${elemConfig.CaseSelectorId}`)[0] : document.getElementById(elemConfig.CaseSelectorId);

  return selectNode;
}

const Main = {
  CascadeLevels: CASCADE_LEVELS,
  renderCascade: renderCascade,
  Cascade: {
    "Civil Court": CivilCourt,
    "Community": Community,
    "District Attorney": DistrictAttorney,
    "Juvenile": Juvenile,
    "Magistrate": Magistrate,
    "Municipal": Municipal,
    "Probate": Probate,
    "State": State,
    "Superior": Superior
  }
}

window.initCasesCascadeFields = function(selectedValues, elem_required = false) {
  $(document).ready(function(){
    CascadeSelects(Main, "case_refferal_source", selectedValues, elem_required);
  });
}

window.initializedCaseCascadeFields = [];

window.initCaseInformationSaveButton = function(targetEvent) {
  $('#case_disposition_status').attr('data-parsley-required-message', "Disposition Status can't be blank")
  document.addEventListener(targetEvent, function(e) {
    if($('#case_status').val() === "Disposed") {
      e.preventDefault();
      $('#caseDisposition').removeClass('d-none')
      $('#case_disposition_status, #case_disposition_date').attr('required', 'required')
      if(!$('#case_disposition_status').prev().children().is('abbr')) {
        $('#case_disposition_status, #case_disposition_date').prev().append("<abbr title='required' style= 'margin-left: 2px;'>*</abbr>")
      }
    } else {
      $('#caseDisposition').addClass('d-none')
      $('#case_disposition_status, #case_disposition_date').removeAttr('required')
      $('#case_disposition_date, #case_disposition_status, #case_disposition_type').val('');
      // $('#case_fees_owed_by_fees_owed_by, #case_fees_owed_by_recant_received').prop('checked', false).trigger('change');
    }
  });
}

window.initCaseIssuesSelect = function() {
  var caseIssuesCategory = initCaseIssuesArray();

  $("#case_refferal_source, #case_case_category").on("change", function(e) {
    var value = this.value;
    var refferal = $('#case_refferal_source').val();
    var refferalValue = ['Civil Court', 'Community', 'District Attorney', 'Juvenile', 'Magistrate', 'Municipal', 'Superior']
    var issuesRefferalValue = ['State','Probate', 'Domestic Relations', 'General Civil']

    if (!(value == 'General Civil' && (refferal == 'State' || refferal == 'Magistrate' || refferal == 'Municipal'))){
      if (value.length == 0 || refferalValue.includes(value)) {
        $("#case_issues").addClass('d-none');
        $('#case_case_issues').val(null)
      } else if(issuesRefferalValue.includes(value)) {
        $('#case_issues').removeClass('d-none')
        $('#case_case_issues').val(null).trigger('change');
        var catOptions = "";
        for (var categoryId in caseIssuesCategory[value]) {
          catOptions += `<option value=${categoryId}>` + caseIssuesCategory[value][categoryId] + "</option>";
        }
        document.getElementById("case_case_issues").innerHTML = catOptions;
      }
    }
  });
}

window.caseIssuesOnReadyOptions = function() {
  var caseIssuesCategory = initCaseIssuesArray();

  $(document).ready(function() {
    var selected_keys = $('#case_case_issues').attr('selected_key');
    var keys = selected_keys.split(' ').filter(v=>v!='');
    var refferal = $('#case_refferal_source').val();
    var category = $('#case_case_category').val();
    var refferalValue = ['Civil Court', 'Community', 'District Attorney', 'Juvenile', 'Magistrate', 'Municipal']

    if (refferal == 'Superior'){
      $("#case_issues").addClass('d-none');
      if (category == 'Domestic Relations' || category == 'General Civil'){
        caseIssuesOptions(category, keys);
      }
    } else if(refferal == 'State' || refferal == 'Probate'){
      caseIssuesOptions(refferal, keys);
    } else if(refferalValue.includes(refferal)){
      $("#case_issues").addClass('d-none');
    }
  });

  var caseIssuesOptions = function(refferalKey, selectedIssues) {
    $('#case_issues').removeClass('d-none')
    var catOptions = "";
    for (var categoryId in caseIssuesCategory[refferalKey]) {
      var isSelected = selectedIssues.includes(categoryId);
      catOptions += '<option value="' + categoryId + '"' + ( isSelected ? 'selected="selected"' : '') +'>' + caseIssuesCategory[refferalKey][categoryId] + '</option>';
    }
    document.getElementById("case_case_issues").innerHTML = catOptions;
  }
}

window.initCaseIssuesArray = function() {
  var caseIssuesCategory = {
    'Domestic Relations': {'ALIMONY': 'Alimony', 'ATTORNEY\'S_FEES': 'Attorney’s Fees', 'CHILD_CUSTODY': 'Child Custody', 'CHILD_SUPPORT': 'Child Support', 'CHILD_VISITATION': 'Child Visitation', 'DEBT': 'Debt', 'NO_ISSUES': 'No Issues', 'PROPERTY_\-_REAL_AND/OR_PERSONAL': 'Property – Real and/or Personal'},
    'General Civil': {'ATTORNEY\'S_FEES': 'Attorney’s Fees', 'BREACH_OF_CONTRACT': 'Breach of Contract', 'CONSTRUCTION_CONTRACT_DISPUTE': 'Construction Contract Dispute', 'PERSONAL_INJURY': 'Personal Injury'},
    'State': {'ATTORNEY\'S_FEES': 'Attorney’s Fees', 'BREACH_OF_CONTRACT':'Breach of contract', 'CONSTRUCTION_CONTRACT_DISPUTE': 'Construction Contract Dispute', 'PERSONAL_INJURY': 'Personal Injury', 'PROPERTY_ISSUES': 'Property Issues'},
    'Probate': {'ACCOUNTING_BY_ADMINISTRATOR': 'Accounting by Administrator', 'ATTORNEY\'S_FEES': 'Attorney’s Fees', 'CAVEAT': 'Caveat', 'DETERMINATION_OF_HEIRS': 'Determination of Heirs', 'OBJECTION_TO_APPOINTMENT': 'Objection to Appointment', 'REMOVAL_OF_ADMINISTRATOR': 'Removal of Administrator', 'YEAR\'S_SUPPORT': "Year's Support"}
  }
  return caseIssuesCategory;
}

window.initCaseIssuesOffensesSelect = function() {
  var caseIssuesOffensesCategory = initCaseIssuesOffensesArray();

  $("#case_refferal_source, #case_case_category").on("change", function(e) {
    var value = this.value;
    var refferal = $('#case_refferal_source').val();
    var refferalValue = ['Civil Court', 'Community', 'District Attorney', 'Magistrate', 'Municipal', 'Superior', 'Probate', 'State', 'Juvenile']
    var juvenile_categories_without_issues = ['Delinquency', 'Dependency', 'Emancipation', 'Special Proceedings', 'Termination of Parental Rights']
    var issuesRefferalValue = ['Children in Need of Services','Class A felony', 'Class B felony', 'Not Designated', 'Traffic']

    if (value.length == 0 || refferalValue.includes(value) || juvenile_categories_without_issues.includes(value)) {
      $("#issues_offenses_case").addClass('d-none');
      $('#case_issues_offenses').val(null)
    } else if(issuesRefferalValue.includes(value)) {
      $('#issues_offenses_case').removeClass('d-none')
      $('#case_issues_offenses').val(null).trigger('change');

      var catOptions = "";
      for (var categoryId in caseIssuesOffensesCategory[value]) {
        catOptions += `<option value=${categoryId}>` + caseIssuesOffensesCategory[value][categoryId] + "</option>";
      }
      document.getElementById("case_issues_offenses").innerHTML = catOptions;
    }
  });
}

window.caseIssuesOffensesOnReadyOptions = function() {
  var caseIssuesOffensesCategory = initCaseIssuesOffensesArray();

  $(document).ready(function() {
    var selected_keys = $('#case_issues_offenses').attr('selected_key');
    var keys = selected_keys.split(' ').filter(v=>v!='');
    var refferal = $('#case_refferal_source').val();
    var category = $('#case_case_category').val();
    var refferalValue = ['Civil Court', 'Community', 'District Attorney', 'Magistrate', 'Municipal', 'Superior', 'Probate', 'State', 'Juvenile']

    if (category == 'Children in Need of Services' || category == 'Class A felony' || category == 'Class B felony' || category == 'Not Designated' || category == 'Traffic'){
      caseIssuesOffensesOptions(category, keys);
    } else if(refferalValue.includes(refferal)){
      $("#issues_offenses_case").addClass('d-none');
    }
  });

  var caseIssuesOffensesOptions = function(refferalKey, selectedIssues) {
    $('#issues_offenses_case').removeClass('d-none')
    var catOptions = "";
    for (var categoryId in caseIssuesOffensesCategory[refferalKey]) {
      var isSelected = selectedIssues.includes(categoryId);
      catOptions += '<option value="' + categoryId + '"' + ( isSelected ? 'selected="selected"' : '') +'>' + caseIssuesOffensesCategory[refferalKey][categoryId] + '</option>';
    }
    document.getElementById("case_issues_offenses").innerHTML = catOptions;
  }
}

window.initCaseIssuesOffensesArray = function() {
  var caseIssuesOffensesCategory = {
    'Children in Need of Services': {'DISOBEDIANT': 'Disobediant', 'LOITERING': 'Loitering', 'PATRONIZING_ADULT_ESTABLISHMENT': 'Patronizing Adult Establishments', 'RUNAWAY': 'Runaway', 'STATUS_OFFENSE': 'Status offense', 'TERMS_OF_SUPERVISION': 'Terms of Supervision', 'TRUANT': 'Truant'},

    'Class A felony': {'AGGRAVATED_ASSAULT': 'Aggravated Assault', 'AGGRAVATED_BATTERY': 'Aggravated Battery', 'ARMED_ROBBERY': 'Armed Robbery', 'ARSON_IN_THE_1ST_DEGREE': 'Arson in the 1st Degree', 'ATTEMPTED_MURDER': 'Attempted Murder', 'DRUG_TRAFFICKING': 'Drug Trafficking', 'ESCAPE': 'Escape', 'GANG_ACTIVITY': 'Gang Activity', 'HIJACKING': 'Hijacking', 'HOME_INVASION': 'Home Invasion', 'KIDNAPPING': 'Kidnapping'},

    'Class B felony': {'AGGRAVATED_ASSAULT': 'Aggravated Assault', 'ARSON_2ND_DEGREE':'Arson 2nd Degree', 'ATTEMPTED_KIDNAPPING': 'Attempted Kidnapping', 'BATTERY_OF_A_TEACHER_OR_OTHER_SCHOOL_PERSONNEL': 'Battery of a Teacher or Other School Personnel', 'DESTRUCTIVE_DEVICES': 'Destructive Devices', 'GANG_ACTIVITY': 'Gang Activity', 'HOME_INVASION_2ND_DEGREE': 'Home Invasion 2nd Degree', 'OBSTRUCTION_OF_LAW_ENFORCEMENT_OFFICER': 'Obstruction of Law Enforcement Officer', 'POSSESSION_OF_A_HANDGUN': 'Possession of a Handgun', 'POSSESSION_OF_A_WEAPON_ON_SCHOOL_PROPERTY': 'Possession of a Weapon on School Property', 'RACKETEERING': 'Racketeering', 'SMASH_AND_GRAB_BURGLARY': 'Smash and Grab Burglary'},

    'Not Designated': {'AFFRAY': 'Affray', 'ASSAULT': 'Assault', 'CASE_PLAN/ISSUES': 'Case Plan/Issues', 'CRIMINAL_TRESPASS': 'Criminal Trespass', 'DESTRUCTION_OF_PROPERTY': 'Destruction of Property', 'DISRPUTING_SCHOOLS': 'Disrputing Schools', 'DUI': 'DUI', 'FELONY_BATTERY': 'Felony Battery', 'G._TERMINATION': 'G. Termination', 'OBSTRUCTION_OF_POLICE': 'Obstruction of Police', 'OTHER': 'Other', 'PERM': 'Perm', 'SEXUAL_BATTERY': 'Sexual Battery', 'SIMPLE_BATTERY_(MISDEMEANOR)': 'Simple Battery (Misdemeanor)', 'TERROISTIC_THREATS': 'Terroistic Threats', 'THEFT_BY_TAKING': 'Theft by Taking', 'VAPING': 'Vaping', 'VISITATION': 'Visitation'},

    'Traffic': {'BUI': 'BUI', 'CURFEW': 'Curfew', 'DUI': 'DUI', 'OTHER': 'Other', 'PARKING': 'Parking', 'SPEEDING': 'Speeding', 'TRAFFIC': 'Traffic'},
  }
  return caseIssuesOffensesCategory;
}
