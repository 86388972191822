import ApexCharts from 'apexcharts'
import runner from "../../lib/initializator"

const initializeCharts = (containerId, data) => {
  var colors = ["#5364FF", "#DB4437", "#FFDB5C"];
  var options = {
      chart: {
          height: 213,
          type: 'donut',
      },
      legend: {
          show: false
      },
      stroke: {
          colors: ['transparent']
      },
      series: data,
      labels: ["In Process", "Disposed", "On Hold"],
      colors: colors,
      responsive: [{
          breakpoint: 480,
          options: {
              chart: {
                  width: 200
              },
              legend: {
                  position: 'bottom'
              }
          }
      }]
  }

  var chart = new ApexCharts(
      document.getElementById(containerId),
      options
  );
  chart.render();
}

window.renderDashboardCaseStatisticChart = function(containerId, data) {
  runner(() => initializeCharts(containerId, data))
}
