window.simple_form_helpers = window.simple_form_helpers || {};

window.simple_form_helpers.removeErrorManually = function(inputId) {
  var inputField = $(`#${inputId}`);
  var group = inputField.closest(`.${inputId}`, inputField);

  group.removeClass('form-group-invalid');
  inputField.removeClass('is-invalid');
  $('.invalid-feedback', group).each((_, element) => {
    element.remove();
  });
};

window.simple_form_helpers.setErrorManually = function(inputId, message, clearField = false) {
  var inputField = $(`#${inputId}`);
  var group = inputField.closest(`.${inputId}`, inputField);

  group.addClass('form-group-invalid');
  inputField.addClass('is-invalid');
  $(`<div class="invalid-feedback">${message}</div>`).insertAfter(inputField);

  if ( clearField ) inputField.val(null);
};

window.validateNumberField = function(inputId, min, max) {
  var parsedMin = parseInt(min);
  var parsedMax = parseInt(max);

  $(`#${inputId}`).on('input', function() {
    var value = parseInt($(this).val());

    if (min != undefined && value < parsedMin) $(this).val(min);
    if (max != undefined && value > parsedMax) $(this).val(max);
  })
};

window.validateForm = function(validations) {
  let validated = true;

  function _validate(field) {
    let $el = $("#" + field);

    $el.removeClass("is-invalid");
    $("." + field + "_err_text").remove();

    validations[field].forEach(validation => {
      let isValid = typeof validation.rule === "function" ?  validation.rule() : $el.val().match(validation.rule);

      if(!isValid) {
        validated = false;

        if(typeof validation.err === "function") {
          validation.err();
        } else {
          $el.addClass("is-invalid");
          $el.parent().append(`<div class='text-danger ${field}_err_text'><i><small>${validation.err}</small></i></div>`);
        }

        return false;
      }
    })
  }

  Object.keys(validations).forEach(field => {
    _validate(field);
  });

  return validated;
};

window.phoneNumberFormate = function(phoneId){
  $(phoneId).on('keyup', function(e){
    text = e.target.value;
    formattedText = text.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    $(phoneId).val(formattedText);
  })
};

window.getSubmitType = function(btnId, btnValue) {
  $(btnId).on('click', function(){
    $("#user_submit_type").val(btnValue);
  })
}
