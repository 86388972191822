import Calendar from "../calendar";
import { values } from "lodash";

import CalendarViewMode from "./base-view";

class WeekView extends CalendarViewMode {
    constructor(events, settings, timeRange) {
        super(events, settings);
        this.__timeRange = timeRange;
    }

    __renderHeader() {
        const cells = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ];

        const today = this.calendar.today();

        this.calendar.saveDateInLocalStorage(this.calendar.d);
        const startDate = new Calendar(this.calendar.d);
        startDate.changeDay(-today.weekDay);

        for(let i = 0; i < cells.length; i++) {
            const isToday = this.calendar.isToday(Calendar.Today().d, this.calendar.getWeekStartDate().d.getDate() + i)
            // var week_start_date = this.calendar.getWeekStartDate().d.getDate() + i;
            // const current_date = this.calendar.getMonthEndDate(week_start_date);
            // const isToday = this.calendar.isToday(Calendar.Today().d, current_date)
            cells[i] = `<div class="cell cell-header ${isToday ? " cell-header-today" : ""}">${startDate.today().day}, ${cells[i]}</div>`;
            startDate.changeDay(1);
        }

        cells.unshift(`<div class="cell cell-header invisible">-</div>`);

        return cells;
    }

    __renderTimeColumn() {
        const column = [];
        const [fromTime, toTime] = this.__timeRange;

        for (let i = fromTime; i < 12 && i < toTime; i++)
            column.push(`<div class="cell cell-${i}">${i}:00 am</div>`);

        for (let i = fromTime < 12 ? 12 : fromTime ; i < toTime; i++)
            column.push(`<div class="cell cell-${i}">${i % 12 || 12}.00 pm</div>`);

        return column;
    }

    __getEventInfo(event) {
        return `
            <span class="display-case-name-session ${event.domesticViolence ? "text-danger" : ""}">${event.title}</span>
            ${event.virtual == 'Virtual' ? `<br/>${event.virtual}<br/>` : ""}
        `;
    }

    __renderColumn(index) {
        const column = [];

        const [fromTime, toTime] = this.__timeRange;

        for(let i = fromTime; i < toTime; i++)
            column.push(`<div class="cell cell-${i}"></div>`);

        let someDate = new Date(this.calendar.getWeekStartDate().d);
        someDate.setDate(someDate.getDate() + index);
        const date = someDate.getDate();
        const month = someDate.getMonth();
        const year = someDate.getFullYear();

        const events = this.__events.filter(event => this.calendar.isEventTodayForWeek(event.start, date, month, year));

        const groupedEvents = this.groupEventsByHour(events);

        values(groupedEvents).forEach(events => {
            column.splice(
                events[0].start.getHours() - fromTime,
                1,
                this.renderEvents(events, this.__getEventInfo, index)
            );
        });
        
        for(let i = fromTime; i < toTime; i++)
            if(typeof column[i] == 'object')
                column[i].addClass(`cell-${i}`);

        return column;
    }

    __renderBodyWrapper() {
        const headerCells = this.__renderHeader();

        const headerRow = $("<div class='week-view-body-wrapper-header'></div>")
        headerRow.append(headerCells)

        const viewObject = $(`<div class="week-view-body-wrapper"></div>`);
        viewObject.append(headerRow);

        return viewObject
    }

    __renderBody() {
        const columns = [];

        columns.push([
            ...this.__renderTimeColumn()
        ]);

        for(let i = 1; i < 8; i++) {
            columns.push([]);
            columns[i].push(...this.__renderColumn(i-1));
        }
        
        const viewObject = $(`<div class="week-view-body"></div>`);
        const columnsContent = columns.map((column, i) => {
            const isToday = this.calendar.isEventTodayForWeek(Calendar.Today().d, this.calendar.getWeekStartDate().d.getDate() + i-1, this.calendar.d.getMonth(), this.calendar.d.getFullYear())
            return $(`<div class="column${i > 0 ? ' column-selectable' : ''} ${isToday ? ' column-today' : ''}"></div>`)
        });
        for(let i = 0; i < 8; i++) {
            columnsContent[i].append(columns[i]);
        }
        viewObject.append(columnsContent);

        return viewObject;
    }

    next() {
        this.calendar.changeWeek(1);
    }

    prev() {
        this.calendar.changeWeek(-1);
    }

    getDateLabel() {
        const startDate = this.calendar.getWeekStartDate();
        const startDay = startDate.today().day;
        const startMonthName = startDate.getMonth().name;
        const endDate = new Calendar("",startDate.d);
        endDate.changeDay(6);
        const endDay = endDate.today().day;
        const endMonthName = endDate.getMonth().number === startDate.getMonth().number ? "" : endDate.getMonth().name;
        return `${startMonthName} ${startDay} - ${endMonthName} ${endDay}, ${this.calendar.d.getFullYear()}`;
    }

    render() {
        const viewObject = $(`<div class="week-view"></div>`);

        const bodyWrapper = this.__renderBodyWrapper();
        bodyWrapper.append(this.__renderBody());

        viewObject.append(bodyWrapper)

        return viewObject;
    }
}

export default WeekView;