import {updateTime} from "../datetime_helpers";
import querystring from "querystring"

window.disableAllInputs = function(inputGroupWrapper) {
  $("input", inputGroupWrapper).each((_, inputItem) => {
    $(inputItem).attr('disabled','disabled');
  });
}

window.enableAllInputs = function(inputGroupWrapper) {
  $("input", inputGroupWrapper).each((_, inputItem) => {
    $(inputItem).removeAttr('disabled');
  });
}

window.toggleInput = function(inputId, targetId) {
  var input = $(inputId);
  var target = $(targetId);

  if (!input.is(":checked")) {
    target.addClass('d-none')
  };

  input.change(function() {
    if (input.is(":checked")){
      target.removeClass('d-none')
    } else {
      target.addClass('d-none')
    }
  });
}

window.togglePair = function(container1, container2) {
  if($(container1).hasClass("d-none")) {
    $(container1).removeClass("d-none");
    $(container2).addClass("d-none");
  } else {
    $(container1).addClass("d-none");
    $(container2).removeClass("d-none");
  }
}

window.toggleSelect = function(selectId, selectValue, targetId) {
  var select = $(selectId);
  var target = $(targetId);

  if(select.val() !== selectValue) {
    target.addClass('d-none')
  }

  select.change(function() {
    if(select.val() === selectValue) {
      target.removeClass('d-none');
    } else {
      target.addClass('d-none');
    }
  })
}

window.radioToggle = function(inputId, showId, hideId, dynamic_class) {
  var input = $(inputId);

  var toggleHandler = function() {
    if (input.is(":checked")) {
      update_cpnr('individual', dynamic_class)
      $(showId).removeClass('d-none')
      enableAllInputs($(showId))
      $(hideId).addClass('d-none')
      disableAllInputs($(hideId))
    } else {
      // company
      update_cpnr('company', dynamic_class)
      $(showId).addClass('d-none')
      disableAllInputs($(showId))
      $(hideId).removeClass('d-none')
      enableAllInputs($(hideId))
    }
  }

  toggleHandler();

  $("input[type='radio']").click(function() {
    toggleHandler();
  });
};

window.update_cpnr = function(party_kind, dynamic_class='cpnr') {
  let desiredOrder = [];

  if (party_kind === 'individual') {
    desiredOrder = [
      "col-3 col-3-5 office_cpnr " + dynamic_class,
      "col-3 col-3-5 cell_cpnr " + dynamic_class,
      "col-3 col-3-5 fax_cpnr " + dynamic_class,
      "col-1 col-1-5 extension_cpnr " + dynamic_class
    ];
    $('.office_cpnr').find('label').text('Home')
    $('.fax_cpnr').find('label').text('Other')
  } else {
    desiredOrder = [
      "col-3 col-3-5 office_cpnr " + dynamic_class,
      "col-1 col-1-5 extension_cpnr " + dynamic_class,
      "col-3 col-3-5 cell_cpnr " + dynamic_class,
      "col-3 col-3-5 fax_cpnr " + dynamic_class
    ];
    $('.office_cpnr').find('label').text('Office')
    $('.fax_cpnr').find('label').text('Fax')
  }
  const divsToReorder = $("." + dynamic_class).get();
  divsToReorder.sort((a, b) => {
    const classA = $(a).attr("class");
    const classB = $(b).attr("class");
    return desiredOrder.indexOf(classA) - desiredOrder.indexOf(classB);
  });
  const container = $("." + dynamic_class + "_number_row");
  container.empty();
  divsToReorder.forEach(div => container.append(div));
}

window.toggleFeeSign = function(inputId, showId) {
  let input = $(inputId);

  if (input.is(":checked")) {
    $(showId).text('%')
  } else {
    $(showId).text('$')
  }

  input.change(function() {
    // Removes error message and red border from input if user has put invalid value and then he unchecks
    // and checks the percentage checkbox
    removeErrorWhenNotNeeded()

    if (input.is(":checked")){
      $(showId).text('%')
      $("#case_party_fee_wavier").val('50')
    } else {
      $(showId).text('$')
      $("#case_party_fee_wavier").val('50.00')
    }
  });
};

// Start Case Party And Case Attendee Interpreter Requested
window.caseInterpreterRequested = function(divId, fieldId, fieldName) {
  if($(`input[name='${fieldName}'][value='true']`).prop("checked")) {
    $(divId).show();
  } else {
    $(divId).hide();
    $(fieldId).val('');
  }
}

window.onChangeCaseInterpreterRequested = function(divId, fieldId, fieldName) {
  // Function calls when the page load
  caseInterpreterRequested(divId, fieldId, fieldName);
  $(`input[name='${fieldName}']`).change(function(){
    // Function calls after the value change
    caseInterpreterRequested(divId, fieldId, fieldName);
  });
}
// End of the Interpreter Requested

// Start Case Party And Case Attendee Assistance Requested
window.caseAdaAssistanceRequested = function(divId, fieldId, fieldName) {
  if($(`input[name='${fieldName}'][value='true']`).prop("checked")) {
    $(divId).show();
  } else {
    $(divId).hide();
    $(fieldId).val('');
  }
}

window.onChangeCaseAdaAssistanceRequested = function(divId, fieldId, fieldName) {
  // Function calls when the page load
  caseAdaAssistanceRequested(divId, fieldId, fieldName);
  $(`input[name='${fieldName}']`).change(function(){
    // Function calls after the value change
    caseAdaAssistanceRequested(divId, fieldId, fieldName);
  });
}
// End of the ADA assistance Requested

function removeErrorWhenNotNeeded() {
  $('#case_party_fee_wavier').removeClass('is-invalid')
  $('#case_party_fee_wavier').parent().parent().children().eq(1).remove()
}

// For Fee waiver status

window.casePartyFeeWaiverStatus = function(firstTime=false) {
  if($("input[name='case_party[fee_waiver_status]'][value='Approved']").prop("checked")) {
    if (!firstTime) {
      // Removes error message and red border from input if user has put invalid value and then he checks
      // pending or denied and then chooses Approved
      removeErrorWhenNotNeeded()
      if ($('#toogle-fee-sign').is(":checked")) {
        $('#fee-wavier-sign').text('%')
      }
      else {
        $('#fee-wavier-sign').text('$')
      }
    }
    $('#fee-wavier-status').show();
    var value = $('#case_party_fee_wavier').val();
    // Added condition to display the default value
    if (value == '')
    {
      if ($('#toggle-fee-sign').prop("checked")) {
        $('#case_party_fee_wavier').val('50');
      }
      else {
        $('#case_party_fee_wavier').val('50.00');
      }
    }
  } else {
    $('#fee-wavier-status').hide();
    $("#toggle-fee-sign").prop("checked", false);
    $('#case_party_fee_wavier').val('');
  }
}

window.onChangeCasePartyFeeWaiverStatus = function() {
  // Function calls when the page load
  casePartyFeeWaiverStatus(true);
  $("input[name='case_party[fee_waiver_status]']").change(function(){
    // Function calls after the value change
    casePartyFeeWaiverStatus(false);
  });
}

// For Has Fee waiver status
window.casePartyHasFeeWaiverChecked = function(firstTime=false) {
  if($('#toggle-fee').is(":checked")) {
    $('#party-fee-wavier-field').show();
    if (!firstTime) {
      $('#toggle-fee-sign').prop("checked", false)
      $('#case_party_fee_wavier').val('50.00');
      // Removes error message and red border from input if user has put invalid value and then he unchecks
      // and checks the fee wavier requested checkbox
      removeErrorWhenNotNeeded()
    }
  } else {
    $('#party-fee-wavier-field').hide();
    $('input[name="case_party[fee_waiver_status]"]').prop('checked', false);
    $('#fee-wavier-status').hide();
  }
}

window.onChangeCasePartyHasFeeWaiverRequested = function() {
  // Function calls when the page load
  casePartyHasFeeWaiverChecked(true);
  $("input[name='case_party[has_fee_wavier]']").change(function(){
    // Function calls after the value change
    casePartyHasFeeWaiverChecked(false);
  });
}

window.casePartyRepresentedByCounsel = function() {
  if($("#case_party_lawyer_ids :selected").length < 1){
    $("#case_party_represented_by_counsel_represented_by_counsel").attr('checked', false)
  }
  if($("input[name='case_party[represented_by_counsel]'][value='Represented By Counsel']").prop("checked")) {
    $('#smart-lawyer-select').show();
  } else {
    $('#smart-lawyer-select').hide();
    var selected_id = $('#case_party_lawyer_ids-container').find('.select-pure__label').children();
    selected_id.each(function(){
      $(this).find('.close-icon').click();
    })
  }
}

window.onChangeCasePartyRepresentedByCounsel = function() {
  // Function calls when the page load
  casePartyRepresentedByCounsel();
  $("input[name='case_party[represented_by_counsel]']").change(function(){
    // Function calls after the value change
    casePartyRepresentedByCounsel();
  });
}

window.caseAttendeeRepresentedByCounsel = function() {
  if($("#case_attendee_lawyer_ids :selected").length < 1){
    $("#case_attendee_represented_attendee_represented_by_counsel").attr('checked', false)
  }
  if($("input[name='case_attendee[represented_attendee]'][value='Represented By Counsel']").prop("checked")) {
    $('#attendee-lawyer-select').show();
  } else {
    $('#attendee-lawyer-select').hide();
    var selected_id = $('#case_attendee_lawyer_ids-container').find('.select-pure__label').children();
    selected_id.each(function(){
      $(this).find('.close-icon').click();
    })
  }
}

window.onChangeCaseAttendeeRepresentedByCounsel = function() {
  // Function calls when the page load
  caseAttendeeRepresentedByCounsel();
  $("input[name='case_attendee[represented_attendee]']").change(function(){
    // Function calls after the value change
    caseAttendeeRepresentedByCounsel();
  });
}

window.casePartyDVScreeningStatusSelect = function() {
  var form = $('form');
  var select = $('#case_party_screen_status');
  var target = $('#domestic-violence-completed-container');
  var clearValue = $('#case_party_screen_date, #case_party_tier2_screen_date');
  let dvTypesWhichShowDates = ['In Progress', 'Completed', 'Tier 2 Screening Required'];

  if ( form.hasClass('case_party_form')){
    if(dvTypesWhichShowDates.includes(select.val())) {
      target.removeClass('d-none')
    } else {
      target.addClass('d-none')
    }
  }

  select.on('change', function(e) {
    if(dvTypesWhichShowDates.includes(select.val())) {
      target.removeClass('d-none')
    } else {
      target.addClass('d-none')
    }
  });
}

window.getCaseValues= function() {
  let inputs = $('.edit_case').serializeArray()
  let values = _.map(inputs, node => {
    if (node.name === 'authenticity_token') return
    return node.value
  })
  return values.join('-')
}

window.initUnsavedCaseModal = function(event, modalHTML) {
  $('#confirm_case_unsaved_save_button').off('click')
  $('#confirm_case_unsaved_continue_button').off('click')

  $('#confirm_case_invalid_data_error').hide()

  if ($('.is-invalid').length > 0) {
    $('#confirm_case_unsaved_save_button').attr('disabled', true)
    $('#confirm_case_invalid_data_error').show()
  }

  $('#confirm_case_unsaved_save_button').on('click', function(e){

    if ($('.is-invalid').length > 0) return

    let value = check_case_number(e, 'update')
    if (!value) {
      showModal('editCaseConfirmModal', modalHTML, 'md')
      initUnsavedCaseFollowUpModal(event)
      return false
    } else {
      let ajax = caseInformationNextButtonAjax(false, false, true)
      ajax.then(() => {
        $(event.currentTarget).trigger('click', { bypass: true });
      })
    }
  });

  $('#confirm_case_unsaved_continue_button').on('click', function(e){
    const element = $('<div></div>')
    element.addClass("bypass-validation")
    $('body').append(element)
    $(event.currentTarget).trigger('click', { bypass: true });
    closeModal('editCaseConfirmModal')
    setTimeout(() => {
      $(event.currentTarget).trigger('click', { bypass: true });
    }, 100)
  })
}

window.initUnsavedCaseFollowUpModal = function(event) {
  $('#unsaved_case_duplicate_save_button').off('click')

  $('#unsaved_case_duplicate_save_button').on('click', function(e){
    let ajax = caseInformationNextButtonAjax(false, false, true)
    ajax.then(() => {
      $(event.currentTarget).trigger('click', { bypass: true });
    })
  })
}

window.parseDateString = (date) => {
  if (typeof date === 'string' && date.length === 8) {
    const dateString = date.substring(0,2) + '/' + date.substring(2,4) + '/' + date.substring(4,8)
    console.log(dateString)
    return new Date(dateString)
  } else {
    return new Date(date)
  }
}

window.dateFormatValidation = function(dateId, saveButtonId, saveNewId = null) {
  $(document).ready(function() {
    // When added date from the date rangepicker
    const checkDatesAfterReferralDate = (dateId, dateVal, element) => {
      if (dateId === '#case_disposition_date') {
        const newDate = parseDateString(dateVal)
        const refferalDate = new Date($('#case_referral_date').val())

        if (newDate < refferalDate) {
          $(element).addClass("is-invalid");
          $(element).parent().siblings('.before-date-error').removeClass('d-none')
        } else {
          $(element).parent().siblings('.before-date-error').addClass('d-none')
        }
      }

      if (dateId === '#case_filling_date') {
        const newDate = parseDateString(dateVal)
        const refferalDate = new Date($('#case_referral_date').val())

        if (newDate > refferalDate) {
          $(element).addClass("is-invalid");
          $(element).parent().siblings('.before-date-error').removeClass('d-none')
        } else {
          $(element).parent().siblings('.before-date-error').addClass('d-none')
        }
      }

      if (dateId === '#case_referral_date') {
        const newDate = parseDateString(dateVal)
        const filingDate = new Date($('#case_filling_date').val())
        const dispositionDate = new Date($('#case_disposition_date').val())

        if ( filingDate && newDate < filingDate) {
          $(element).addClass("is-invalid");
          $(element).parent().siblings('.before-date-error-filing').removeClass('d-none')
        } else {
          $(element).parent().siblings('.before-date-error-filing').addClass('d-none')
        }
        if ( dispositionDate && newDate > dispositionDate) {
          $(element).addClass("is-invalid");
          $(element).parent().siblings('.before-date-error-disposition').removeClass('d-none')
        } else {
          $(element).parent().siblings('.before-date-error-disposition').addClass('d-none')
        }
      }
    }

    $(dateId).on('apply.daterangepicker', function(ev, picker) {

      let value = $(this).parent().siblings().is(':visible') || $(this).parent().is(':visible')
      let dateVal = $(this).val();

      if(value && ValidateDate(dateVal, dateId)){
        $(this).removeClass("is-invalid");
        // Added condition for the plaintiff, defendant and recant received
        if (dateId == '.date_format' || dateId == '#select_due_date_for_filter') {
          $(this).siblings('span:not(.before-date-error)').remove();
        } else {
          $(this).parent().siblings(':not(.before-date-error)').addClass('d-none')
        }

        checkDatesAfterReferralDate(dateId, dateVal, this)
      }
    });

    // On the change of the date field
    $(dateId).on('change', function(e){
      let dateVal = $(this).val();

      if(ValidateDate(dateVal, dateId)) {
        $(this).removeClass("is-invalid");
        // Added condition for the plaintiff, defendant and recant received
        if (dateId == '.date_format' || dateId == '#select_due_date_for_filter') {
          $(this).siblings('span').remove();
        } else {
          $(this).parent().siblings(':not(.before-date-error)').addClass('d-none')
        }
        checkDatesAfterReferralDate(dateId, dateVal, this)
      } else {
        $(this).addClass("is-invalid");
        // Added condition for the plaintiff, defendant and recant received
        if (dateId == '.date_format' || dateId == '#select_due_date_for_filter') {
          if ($(this).parent().find('span').length == 0) {
            $(this).parent().append(`<span class='text-danger ${dateId}_err_text'><small>Please enter a valid date format. Format can either be MM/DD/YYYY or MMDDYYYY</small></span>`);
          }
        } else {
          $(this).parent().siblings(':not(.before-date-error)').removeClass('d-none')
        }
      }
    });
  });

  $(`${saveButtonId}, ${saveNewId}`).on('click', function(e){
    if (($(dateId).hasClass('is-invalid') == true) && $(dateId).val() !== ''){
      e.preventDefault();
    }
  });

  function ValidateDate(dateValue, dateId) {
    if (dateValue.length > 0) {
      if (dateId == '#session_session_date'){
        let dtRegex = new RegExp(/^(0[1-9]|1[012])(\/|)(0[1-9]|[12][0-9]|3[01])(\/|)[0-9]{4}\s(0[0-9]|1[0-2]):(0[0-9]|[1-59]\d)\s(AM|am|PM|pm)$/);
        return dtRegex.test(dateValue);
      } else if(dateId == '#select_due_date_for_filter'){
        let dtRegex = new RegExp(/(((0)[0-9])|((1)[0-2]))\/([0-2][0-9]|[3][0-1])\/((19|20)[0-9]{2})[ ][-][ ](((0)[0-9])|((1)[0-2]))\/([0-2][0-9]|[3][0-1])\/((19|20)[0-9]{2})/)
        return dtRegex.test(dateValue);
      } else {
        let dtRegex = new RegExp(/^(0[1-9]|1[012])(\/|)(0[1-9]|[12][0-9]|3[01])(\/|)[0-9]{4}$/);
        return dtRegex.test(dateValue);
      }
    } else {
      return true
    }
  }
}

window.fixModalScrollingIssue = function() {
  $('body').on('hidden.bs.modal', function () {
    if($('.modal:visible').length > 0) {
      $('body').addClass('modal-open');
    }
  });
}

window.capitalize = function(thisInput) {
  // Get start position and end position, in case of an selection these values will be different.
  let selection = getInputSelection(thisInput);
  thisInput.value = thisInput.value.toUpperCase()
  // When shift key or caps lock key is pressed, then code should not work because selection of text through
  // shift + left arrow or shift + right arrow key.
  if (!event.key == "Shift" || event.key != "CapsLock") {
    setInputSelection(thisInput, selection.start, selection.end);
  }
}

function getInputSelection(element) {
  let start = 0, end = 0;

  start = element.selectionStart;
  end = element.selectionEnd;

  return {
    start: start,
    end: end
  };
}

function setInputSelection(element, startOffset, endOffset) {
  element.selectionStart = startOffset;
  element.selectionEnd = endOffset;
}

window.updateColorOfNoteIcon = function(buttonId, noteId, caseNoteId) {
  $(buttonId).on('click', function(e){
    if ($(noteId).is(':checked')){
      $(caseNoteId).addClass('text-success');
    }
  });
}

window.userNeutralClearValues = function(fieldId) {
  var input = $(fieldId);

  input.change(function() {
    if(input.is(':checked')) {
      $('#user_neutral_state_certification_number').val('');
      $('#user_neutral_fee_amount').val('');
      $('#user_neutral_additional_payment_information').val('');
      $('#registration-categories').val(null).trigger('change');
      $('#user_confidential_notes').val('');
    }
  });
}

window.amountFormatValidation = function(thisInput, saveButtonId, saveNewId = null) {
  $(thisInput).parent().next().remove()
  $(thisInput).removeClass("is-invalid");
  let amount = thisInput.value;
  let pattern = /^[-]?(?:\d+|[1-9]\d{0,2}(?:,\d{3})*)(?:\.\d{1,2})?$/; // Optional negative sign, decimal and commas
  let result = new RegExp(pattern).test(amount);
  if(result || !amount) {
    $(thisInput).removeClass("is-invalid");
    $(thisInput).parent().nextAll().remove();
  }
  else {
    $(thisInput).addClass("is-invalid");
    if ($(thisInput).parent().parent().find('span').length == 1) {
      $(thisInput).parent().parent().append(
        `<span id="amount_error_text" class='text-danger ${thisInput.id}_err_text'>
          <small>
            Please enter a valid dollar amount.
          </small>
        </span>`
      );
    }
  }

  $(`${saveButtonId}, ${saveNewId}`).on('click', function(e){
    if (($(thisInput).hasClass('is-invalid') == true)){
      e.stopPropagation();
      XMLHttpRequest.prototype.send = function() {
        return false;
      }
      e.preventDefault();
    }
  });
}

window.removeInputValuesInFieldsInCasePartiesFormAfterToggle = function() {
  const individualasBusinessInputsDivId = $("#individual-as-business-inputs")
  const individualasBusinessId = $("#individual-as-business")
  const companyInput = $(`input[name='case_party[company]']`)

  $("input[name='case_party[kind]']").change(function() {
    // As radioToggle function disables but not removing old values from db,
    // below functions are called.
    enableAllInputs("#individual")
    enableAllInputs("#company")

    companyInput.val("");
    if ($("#case_party_kind_individual").is(":checked")) {
      enableAllInputs(individualasBusinessInputsDivId)
    }
    else {
      disableAllInputs(individualasBusinessInputsDivId)
    }

    individualasBusinessId.prop('checked', false);
    individualasBusinessInputsDivId.addClass("d-none");

    $("#case_party_salutation option:eq(0)").prop('selected', true);
    $(`input[name='case_party[first_name]']`).val("");
    $(`input[name='case_party[middle_name]']`).val("");
    $(`input[name='case_party[last_name]']`).val("");
    $("#case_party_suffix option:eq(0)").prop('selected', true);
  });

  individualasBusinessId.change(function() {
    companyInput.val("");
  })
}

window.updateStatusOfReminder = function(id) {
  const bellIcon = $(`#markReminderButton_${id}`)
  const noteStatus = $(`#noteStatus_${id}`)

  bellIcon.on("click", function(e) {
    if (noteStatus.text() == "Open") {
      bellIcon.removeClass("mdi-bell-outline")
      bellIcon.addClass("mdi-bell")
      noteStatus.text("Closed")
    } else {
      bellIcon.removeClass("mdi-bell")
      bellIcon.addClass("mdi-bell-outline")
      noteStatus.text("Open")
    }
    // Update Last modified date and time in UI as we updated a record by executing this function.
    updateTime(`#noteUpdatedAt_${id}`)
  })
}

window.hoverEffectOnBellIcons = function(id) {
  const bellIcon = $(`#markReminderButton_${id}`)
  const noteStatus = $(`#noteStatus_${id}`)

  bellIcon.on({
    mouseenter: function() {
      bellIcon.removeClass("mdi-bell")
      bellIcon.addClass("mdi-bell-outline")
    },
    mouseleave: function() {
      if (noteStatus.text() == "Closed") {
        bellIcon.addClass("mdi-bell")
        bellIcon.removeClass("mdi-bell-outline")
      }
    }
  });
}

// function searchFunction(url, search_btn_id, show_close_icon_class){
window.searchFunction = function(url, search_btn_id, show_close_icon_class, search_field, user_filter, role_filter) {
  if($(search_btn_id).val() != ""){
    $(show_close_icon_class).show()
  }
  else{
    $(show_close_icon_class).hide()
  }
  $.ajax({
    method: "GET",
    dataType: "script",
    url: url,
    data: {q: search_field, is_locked: user_filter, by_role: role_filter}
  });
}

window.clearSearchFunction = function(url, search_btn_id, show_close_icon_class, search_field, user_filter, role_filter) {
  $(search_btn_id).val('');
  $.ajax({
    method: "GET",
    dataType: "script",
    url: url,
    data: {q: search_field, is_locked: user_filter, by_role: role_filter},
    success: function() {
      $(show_close_icon_class).hide()
    }
  });
}

window.resetFilter = function(elementClass, paramName, context, class_name = ".case-tag-filter") {
  let reset_button = $(elementClass).children().last().children().last().children().eq(1)
  let trigger_element = $(elementClass).find(class_name);
  reset_button.on('click', function(e) {
    let params = new URLSearchParams(window.location.search)
    params.delete(`${paramName}[]`);
    let symbol = '';
    if (params.toString() != "") {
      symbol = '?';
    }
    let url = window.location.origin + window.location.pathname + symbol + params.toString();
    window.sessionStorage.setItem('params_'+`${context}`, url)
    if (url && (context == "cases" || context == 'users' || context == 'dashboard' || context == 'sessions')) {
      window.location.replace(url);
    } else {
      trigger_element.trigger('change');
      trigger_element.removeClass('bg-primary text-white');
    }
  })
}


window.multiSelectWithCallback = function(id, name, callback) {


  $(id).multiselect({
    enableCaseInsensitiveFiltering: true,
    includeResetOption: true,
    numberDisplayed: 1,
    inheritClass: true,
    includeResetDivider: true,
    nonSelectedText: `Select ${name}`,
    buttonText: function(options, select) {
      if (options.length > 0) {
        return `Select ${name} (${options.length})`
      }
      else {
        return `Select ${name}`
      }
    },
    onChange: callback
  });
}

window.implementMultiSelect = function(id, name, param, joinBy, context) {
  $(id).multiselect({
    enableCaseInsensitiveFiltering: true,
    includeResetOption: true,
    numberDisplayed: 1,
    inheritClass: true,
    includeResetDivider: true,
    nonSelectedText: `Select ${name}`,
    buttonText: function(options, select) {
      if (options.length > 0) {
        return `Select ${name} (${options.length})`
      }
      else {
        return `Select ${name}`
      }
    },
    onChange: function(option, checked, select) {
      let url = window.location.href;
      let symbol = '?';
      if (checked) {
        if (url.includes('?')) {
          symbol = '&';
        }
        else {
          symbol = '?';
        }
        url = url + symbol + `${param}%5B%5D=` + option.val().split(' ').join(joinBy);
      }
      else {
        url = url.replace(`${param}%5B%5D=${option.val().split(' ').join(joinBy)}`, "");
        // While deleting param present in the middle, if ?& appears together, we will remove & as the param will be the 1st param to be present in the url.
        url = url.replace('?&', '?');
        // While deleting source present in the middle, if & appears more than once, we will retain only 1 & in the url.
        url = url.replace(/&+/, '&');
        // If at last & or ? is present, then we will remove it.
        if (url[url.length - 1] === '&' || url[url.length - 1] === '?') {
          url = url.slice(0, -1);
        }
      }
      window.sessionStorage.setItem(`params_${context}`, url);
      if (url && ( context == "cases" || context == 'users' || context == 'dashboard' || context == 'sessions')) {
        window.location.replace(url);
      }
    }
  });
}

window.showHideCloseIconSearch = function(fieldId, iconClass) {
  $(fieldId).on('keyup', function() {
    if ($(fieldId).val() != "") {
      $(iconClass).show();
    }
    else {
      $(iconClass).hide();
    }
  });
}

window.redirectToPageWithFilter = function(context, userRole = 'tenant', search=true) {
  const bypass = $('.bypass-validation').length > 0

  if (typeof caseValues != "undefined" && !bypass) {
    let unsavedChanges =  caseValues !== getCaseValues()
    if (unsavedChanges) return
  }

  let url = window.location.origin + `/${userRole}/dashboard`;
  if (context != "dashboard") {
    url = url + "/" + context;
  }
  if (search && ![null, ''].includes(window.sessionStorage.getItem(`params_${context}`))) {
    url = window.sessionStorage.getItem(`params_${context}`);
  }
  if (url) {
    window.location.replace(url);
  }
}

window.clearParamsInSessionStorage = function(context) {
  window.sessionStorage.setItem(`params_${context}`, '');
}

window.putCursorAtTheEnd = function($this) {
  $this.setSelectionRange($this.value.length, $this.value.length)
}

window.submitNewCase = function() {
  $('#confirm_new_case').on('click', function(){
    $('#new_case_submit_button').trigger('click')
  });
}

window.initCaseInformationNextButton = function(modalHTML, invalidModal) {
  $('#case_information_next_button').off('click')
  $('.next-btn').off('click')
  $('.case-info-side-link, .three-dots').off('click')

  $('#case_information_next_button').on('click', function(e){
    // validate the form on next button of case information
    if(!($('.edit_case').parsley().validate())) {
      return false;
    }

    // If user click on next button in case information button the first check the case is not duplicate
    // If case is duplicate then show the confirmation button and intialize the confirmation button of modal's yes response
    // Otherwise call the next button ajax
    let value = check_case_number(e, 'update')
    if(!value) {
      showModal('editCaseConfirmModal', modalHTML, 'md')
      confirmCaseInformationButton();
      return false
    } else {
      caseInformationNextButtonAjax();
    }

  });

  $('.next-btn').on('click', function(e) {
    e.preventDefault()
    $('.case-info-side-link').addClass('side-nav-link')
    $($('a[href="' + $(this).attr('href') +'"]')).removeClass('side-nav-link')
  });

  $('.case-info-side-link, .three-dots').on('click', function(e) {
    const sideLinkClicked = $(e.currentTarget).hasClass('case-info-side-link')
    const threeDotsClicked = $(e.currentTarget).hasClass('three-dots')

    // Clicking the side link will collapse the panel if you click on the active panel. This is something
    // we want to avoid.  Adding checks here that if the link we are clicking is already the active
    // panel we will do nothing.
    if (sideLinkClicked) {
      const href = $(this).attr('href')
      const panel = $(href)
      if (panel.length === 1 && panel.hasClass('show')){
        e.stopPropagation()
        e.preventDefault()
        return
      }
    }

    if (sideLinkClicked && $('.is-invalid').length > 0) {
      e.stopPropagation()
      e.preventDefault()
      showModal('invalidCaseModal', invalidModal, 'md')

      return
    }

    let unsavedChanges =  caseValues !== getCaseValues()
    if (!unsavedChanges) {
      if (sideLinkClicked) {
        $('.case-info-side-link').addClass('side-nav-link')
        $(this).removeClass('side-nav-link')
      }
      return
    }

    if ($('.is-invalid').length > 0) return

    let value = check_case_number(e, 'update')
    if(!value) {
      showModal('editCaseConfirmModal', modalHTML, 'md')
      confirmCaseInformationButton($(this).attr('href'), threeDotsClicked);
      return false
    } else {
      var next_button = $(this).attr('href')
      var ellipsis = false
      if(threeDotsClicked){
        next_button = '#caseInformationTab'
        ellipsis = true
      }

      const ajax = caseInformationNextButtonAjax(next_button, ellipsis);
      ajax.then(() => {
        window.caseValues = getCaseValues()
      })
    }

    if (sideLinkClicked) {
      $('.case-info-side-link').addClass('side-nav-link')
      $(this).removeClass('side-nav-link')
    }
  });
}

window.confirmCaseInformationButton = function(side_link = false, ellipsis = false) {
  $('#confirm_case_information_next_button').off('click')
  $('#confirm_case_information_save_button').off('click')

  // on pressing yes button from the dialog of next button
  $('#confirm_case_information_next_button').on('click', function(e){
    // Call Ajax for update
    // side_link is present for side-links of case page
    const ajax = caseInformationNextButtonAjax(side_link, ellipsis);
    ajax.then(() => {
      window.caseValues = getCaseValues()
    })
  });
  $('#confirm_case_information_next_button').next('button').on('click', function(){
    $('a[href="#caseInformationTab"]').removeClass('side-nav-link')
  });
  // on pressing yes button from the dialog of save button
  $('#confirm_case_information_save_button').on('click', function(e){
    //  trigger click event for submit button
    $('#edit_case_submit_button').trigger('click');
  });
}

window.caseInformationNextButtonAjax = function(side_link = false, ellipsis = false, unsaved = false) {
  var formdata = $('.edit_case').serializeArray();

  if(side_link) {
    var data = { name: 'side_link', value: side_link }
  } else {
    var data = { name: 'next_button', value: true }
  }
  formdata.push(data);

  formdata.push({ name: 'ellipsis', value: ellipsis })
  formdata.push({ name: 'unsaved', value: unsaved })

  formdata.push(data);
  let ajax = $.ajax({
    headers: {
    'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
    },
    method: "PATCH",
    url: "/tenant/dashboard/cases/" + $('#case_id').val(),
    dataType: 'script',
    data: formdata
  });
  closeModal('editCaseConfirmModal')
  return ajax
}

window.case_number_changed = function(data) {
  const caseNumber = data.find((d) => d.name === 'case[case_number]')
  return caseNumber && caseNumber.value && caseNumber.value !== window.adr_case_number
}

window.county_changed = function(data) {
  const county = data.find((d) => d.name === 'case[county]')
  return county && county.value && county.value !== window.adr_case_country
}

window.check_case_number = function(e, formType) {
  let success = false;
  let checkCaseNumber = true

  if(formType == 'new') {
    var formdata = $('#new_case').serializeArray();
    checkCaseNumber = true
  } else {
    var formdata = $('.edit_case').serializeArray();
    checkCaseNumber = case_number_changed(formdata) || county_changed(formdata)
  }

  if (!checkCaseNumber) return true
  var data = {}

  $.each(formdata, function(i, field){
    data[field.name] = field.value
  });

  if((formType === 'update' && !($('.edit_case')[0].checkValidity())) || (formType === 'new' && !($('#new_case')[0].checkValidity()))) {
    return true
  }

  $.ajax({
    headers: {
    'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
    },
    method: "POST",
    url: "/tenant/dashboard/cases/" + $('#case_id').val() + "/is_case_number_unique",
    dataType: 'script',
    async: false,
    data: {
      case_number: data['case[case_number]'],
      county: data['case[county]'],
      form_type: formType
    },
    success: function (data) {
      success = data === 'true'
    }
  })
  return success
}

window.insert_case_party_list_row = function(index, insertedContent) {
  console.log("content", insertedContent)
  if(index == 0 ) {
    if ($('#parties tbody tr').length) {
      console.log("first")
      $('table#parties.table > tbody > tr').eq(0).before(insertedContent);
    } else {
      console.log("second")
      $("#parties tbody").html(insertedContent)
    }
  } else {
    console.log("third")
    $('table#parties.table > tbody > tr').eq(index - 1).after(insertedContent);
  }
}

window.insert_case_party_row = function(index, insertedContent) {
  if(index == 0 ) {
    if ($('#parties tbody tr').length) {
      $('table#parties.table > tbody > tr').eq(0).before(insertedContent);
    } else {
      $("#parties tbody").html(insertedContent)
    }
  } else {
    $('table#parties.table > tbody > tr').eq(index - 1).after(insertedContent);
  }
}

window.changeMenubar = function(_this) {
  $('.side-nav-item').css('background-color', '')
  $('.side-nav-item').find('a').css('pointer-events', '')
  $('.side-nav-item').find('span').css('color', 'white')
  _this.find('span').css('color', 'black')
  _this.find('a').css('pointer-events','none')
  _this.css('background-color', 'white')
}

window.update_plaintiff_defendent_select_list = function(elem_id, array_values){
  $(`#${elem_id}`).empty();
  $.each(array_values, function(key,value) {
    $(`#${elem_id}`).append(`<option value=${value}>${value}<option>`)
  });
  $(`#${elem_id}`).select2();
}
