import moment from "moment"

$(document).on('change', 'select.case-tag-filter', function(){
    url = window.location.href;
    array_url = url.split('/');
    case_id = array_url[array_url.length - 2]
    custom_url = $(this).data("update-url") 
    $.ajax({
      method: "GET",
      url: "/tenant/dashboard/cases/" + custom_url,
      data: { tags: $(this).val() }
    });
});

window.toggle_filter_color = function (apply, id, class_name = '.case-tag-filter') {
  let tag_button =  $(id).parent().find(class_name);
  if(apply) {
    tag_button.addClass('bg-primary text-white');
  } else {
    tag_button.removeClass('bg-primary text-white');
  }
}

$(document).on('change', 'select#select_form_tag_for_filter.form-tag-filter', function(){
  $.ajax({
    method: "GET",
    url: '/tenant/dashboard/render_templates',
    dataType: 'script',
    data: { tags: $(this).val() }
  });
});

$(document).on('change', 'select.attendee-roster-filter', function(){
  url = $(this).data('update-url'); 
  selected_kinds = $('#select_kind_for_filter').val();
  selected_roles = $('#select_role_for_filter').val();
  search_field = $('#q_first_name_or_last_name_or_case_attendee_full_name_or_role_or_email_cont').val();
  $.ajax({
    method: 'GET',
    dataType: 'script',
    url: url,
    data: {search: {search_cont: search_field}, kind: selected_kinds, role: selected_roles }
  });
});

window.getLastYearDateRange = function() {
  const currentDate = moment();
  const endDate = moment().subtract(1, 'years').endOf('year');
  const startDate = moment().subtract(1, 'years').startOf('year');
  const formattedStartDate = startDate.format('MM/DD/YYYY');
  const formattedEndDate = endDate.format('MM/DD/YYYY');
  return `${formattedStartDate} - ${formattedEndDate}`;
}
