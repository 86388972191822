import Calendar from "../calendar";
import { uniq, fromPairs } from "lodash";

class CalendarViewMode {
    constructor(events, settings) {
        this.__events = events;
        this.__canViewEventInfo = JSON.parse($(settings.selector).attr('can-view'));
        this.calendar = new Calendar(settings.customDate);
    }

    groupEventsByHour(events) {
        const hours = uniq(events.map(event => event.start.getHours()));

        const groups = fromPairs(hours.map(h => [h,[]]));

        events.forEach(event => groups[event.start.getHours()].push(event));

        return groups;
    }

    renderEvent(event, getEventInfo, event_height = "auto") {
        const labelClass = event.caseStatus.toLowerCase().split(' ').join('-');
        const ev = $(`<div class="event" style= "background-color: ${event.colorBy};color: ${getFontColor(event.colorBy)}; height: ${event_height}" title="Case Number: ${event.caseNumber}\nCase Name: ${event.caseTitle}\nDate: ${event.start}\nTime: ${Calendar.formatAMPM(event.start)}\nLocation: ${event.location}\nMediator: ${event.mediatorForSession}\nStatus: ${event.sessionStatus}">
                    ${labelClass && `<div class="label-status mb-1 label-status-${labelClass}"></div>`}
                    ${getEventInfo(event)}
                </div>`);

        ev.on('click', (e) => {
            if(this.__canViewEventInfo)
                $.ajax({
                    method: "GET",
                    dataType: "script",
                    url: event.caseShowUrl,
                });
            else
                location.replace(`${event.caseShowUrl}/edit`)
        });

        return ev;
    }

    popupDiv() {
        var obj = this;
        var parent = obj.closest(".event");
        var first = parent.firstElementChild;
        var last = parent.lastElementChild;
        
        
        parent.classList.add('event-z');
        if(obj.innerText == "Show more") {
            obj.innerText = "Show less";
            last.append(this);
            last.style.display = "block";
        } else {        
            obj.innerText = "Show more";
            first.append(this);
            last.style.display = "none";
        }
    }

    renderEvents(events, getEventInfo, columnIndex) {

        if(events.length > 1) {
            const cell = $(`<div class="cell"></div>`);

            const ev = $(`<div class="event d-flex align-items-center justify-content-center">
            </div>`);

            const popup = $(`<div class="event-popup${columnIndex > 3 ? ' event-popup-left' :''}"></div>`);

            events.slice(0, 2).forEach(event => popup.append(this.renderEvent(event, getEventInfo)));

            const extraPopup = $(`<div class="extraPopup event-popup${columnIndex > 3 ? ' event-popup-left' :''}"></div>`);

            events.slice(2, events.length).forEach(event => extraPopup.append(this.renderEvent(event, getEventInfo)));

            const showMore = $(`<div class="show-more" id="showMoreData">Show more</div>`);
            if(events.length > 2) {
                var data_events = JSON.stringify(events);
                var data_popup = (popup);
            
                showMore.attr("events", data_events);
                showMore.attr("popup", data_popup);
                showMore.on('click', this.popupDiv)
                popup.append(showMore);
            }

            ev.append(popup);
            ev.append(extraPopup);
            cell.append(ev);
            return cell;
        } else
            return this.renderEvent(events[0], getEventInfo, "115px");
    }
}

function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getFontColor(color) {
  let {r, g, b} = hexToRgb(color);
  return (r*0.299 + g*0.587 + b*0.114) > 186 ? "#000000" : "#ffffff"
}

export default CalendarViewMode;