export default class FileUploader {
    uploadAsBytes(file, successCb, errorCb){
        let reader = new FileReader();

        reader.onload = (e) => {
            let stringData = e.target.result;

            let status = true;
            let statusText = null;

            successCb({
                name:  file['name'],
                status: status,
                statusText: statusText,
                data: stringData,
                type: file['type']
            });
        };

        reader.onerror = errorCb;

        reader.readAsDataURL(file);
    }

    uploadAsText(file, successCb, errorCb) {
        let reader = new FileReader();

        reader.onload = (e) => {
            let stringData = reader.result;

            let status = true;
            let statusText = null;

            successCb({
                name:  file['name'],
                status: status,
                statusText: statusText,
                data: stringData,
                type: file['type']
            });
        };

        reader.onerror = errorCb;

        reader.readAsText(file, 'UTF-8');
    }
}