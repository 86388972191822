import { CascadeSelects } from "../../lib/cascaded_selects"

const STATE = {
  CascadeSelectorContainerId: "state_container",
  CascadeSelectorName: "tenant[state]",
  CaseSelectorId: "tenant_state",
  CaseInputLabel: "State"
}

const COUNTIES = {
  CascadeSelectorContainerId: "counties_container",
  CascadeSelectorName: "tenant[counties][]",
  CaseSelectorId: "tenant_counties",
  CaseInputLabel: "Counties"
}

const CASCADE_LEVELS = [STATE, COUNTIES]

const renderCascade = (elemConfig, rootForm) => {
  const rawHtml = `<div class="form-group select required case_refferal_source">
    <label class="select required" for="case_refferal_source">
      ${elemConfig.CaseInputLabel}
    </label>
    <select class="select required form-control" required="required" aria-required="true" name="${elemConfig.CascadeSelectorName}" id="${elemConfig.CaseSelectorId}" ${elemConfig.multiple ? "multiple" : ""}>
    </select>
  </div>`

  const template = document.createElement('template');
  template.innerHTML = rawHtml.trim();

  const cascadeNodeContainer = rootForm ? rootForm.find(`#${elemConfig.CascadeSelectorContainerId}`)[0] : document.getElementById(elemConfig.CascadeSelectorContainerId);

  const node = template.content.firstChild;

  while (cascadeNodeContainer.firstChild) {
    cascadeNodeContainer.removeChild(cascadeNodeContainer.firstChild);
  }

  cascadeNodeContainer.append(node)

  const selectNode = rootForm ? rootForm.find(`#${elemConfig.CaseSelectorId}`)[0] : document.getElementById(elemConfig.CaseSelectorId);

  return selectNode;
}

const buildConfig = (data) => {
  const result = {
    CascadeLevels: CASCADE_LEVELS,
    renderCascade: renderCascade,
    Cascade: {}
  }

  Object.keys(data).map(function(state, _index) {
    const counties = data[state];
    const countiesConfig = {}

    counties.forEach((item) => countiesConfig[item] = {Title: item} )

    const stateConfig = {Title: state, Cascade: countiesConfig, multiple: true}

    result.Cascade[state] = stateConfig;
  });

  return result
}

window.initTenantCascadeFields = function(rawMap, selectedValues) {
  document.addEventListener('DOMContentLoaded', function(){
    CascadeSelects(buildConfig(rawMap), "tenant_state", selectedValues)
  });
}









