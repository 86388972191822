window.initFileValidation = function(inputId, maxSize) {
  var $inputId = inputId;
  var $maxSize = parseInt(maxSize);

  var validateFileExtention = function(file, inputId) {
    var acceptFileTypes = /(.*?)(jpg|jpeg|png|pdf|gif|doc|docx|xls|xlsx|ppt|txt)$/i;

    if ( !acceptFileTypes.test(file.name) ) {
      window.simple_form_helpers.setErrorManually(inputId,
                                                  `File type not allowed. You could upload files with extentions: jpg, jpeg, png, pdf, gif, doc, docx, xls, xlsx, ppt, txt`,
                                                  true)
    };
  };

  var validateFileSize = function(file, inputId, maxSize) {
    if ( file.size > maxSize ) {
      window.simple_form_helpers.setErrorManually(inputId,
                                                  `Maximum file size: ${window.utils.formatBytes(maxSize)}`,
                                                  true)
    };
  };

  var validateFile = function() {
    window.simple_form_helpers.removeErrorManually($inputId);

    var file = $(this)[0].files[0];
    if ( !file ) return;

    validateFileExtention(file, $inputId);
    validateFileSize(file, $inputId, $maxSize);
  };

  $(document).on("change", `#${$inputId}`, validateFile);
};
