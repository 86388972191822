window.initSessionLocationInputsHandler = function(inputId, inputsGroupId) {
  var input = $(`#${inputId}`);
  var inputsGroup = $(`#${inputsGroupId}`);

  var inputsHandler = function() {
    var value = input.val();

    if (!!value) {
      inputsGroup.removeClass("hidden");
    } else {
      inputsGroup.addClass("hidden");
    };
  };

  $(document).on("change", `#${inputId}`, inputsHandler);

  inputsHandler();
};
