import runner from "../lib/initializator"
import moment from "moment"

const singleDatePicker = (id, options) => {
  if (!options) options = {}

  const defaultOptions = {
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false
    // locale: {format: "YYYY-MM-DD"}
  }

  const config = Object.assign({}, defaultOptions, options)

  var dateField =  $(`#${id}`);
  if(!id.includes("for_r_r_date_of_") && !id.includes("for_date_of_")){
    if ( !!dateField.val()) {
      // Removing 'UTC' from date which causes firefox to show 'Invalid date' in the UI
      let date = dateField.val(dateField[0].defaultValue.split(" ").slice(0, 2).join(" "))
      dateField.val(
        moment(new Date(date.val()), 'YYYY-MM-DD').format('MM/DD/YYYY')
      );
    };
  }

  // Check the values and convert the date format
  if(id.includes("for_r_r_date_of_") || id.includes("for_date_of_")){
    if ( !!dateField.val()) {
      dateField.val(
       moment(dateField.val(), 'MM/DD/YYYY').format('MM/DD/YYYY')
      );
    };
  }

  dateField.daterangepicker(config).on('apply.daterangepicker', function (ev, picker) {
    dateField.val(picker.startDate.format('MM/DD/YYYY'));
  });
};

const singleDateTimePicker = (id, options) => {
  if (!options) options = {}

  const defaultOptions = {
    autoUpdateInput: false,
    singleDatePicker: true,
    showDropdowns: true,
    timePicker: true,
    timePicker24Hour: false,
    startDate: moment().startOf('day'),
    locale: {format: "MM/DD/YYYY hh:mm A"}
  }
  var dateField = $(`#${id}`);

  const config = Object.assign({}, defaultOptions, options);

  dateField.daterangepicker(config).on('apply.daterangepicker', function (ev, picker) {
    dateField.val(picker.startDate.format('MM/DD/YYYY hh:mm A'));
  });
  // $(`#${id}`).daterangepicker(config)
}

const dateRanges = {
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment().endOf('year')],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
}

const getTitle = (startDate, endDate) => {
  for(let [key, value] of Object.entries(dateRanges))
    if(value[0].isSame(startDate) && value[1].isSame(endDate))
      return key;

  return `${startDate.format('YYYY/MM/DD')} to ${endDate.format('YYYY/MM/DD')}`;
}

const rangeDatePicker = (id, options, yearly=false) => {
  if (!options) options = {}
  if(yearly){
    window.ranges = {
      'This Year': dateRanges['This Year'],
      'Last Year': dateRanges['Last Year'],
    }
  } else {
    window.ranges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'Next 7 Days': [moment(), moment().add(6, 'days')],
      'Next 30 Days': [moment(), moment().add(29, 'days')],
      'This Month': dateRanges['This Month'],
      'Last Month': dateRanges['Last Month'],
      'This Year': dateRanges['This Year'],
      'Last Year': dateRanges['Last Year'],
    }
  }

  const defaultOptions = {
    autoUpdateInput: false,
    showDropdowns: true,
    locale: {format: "MM/DD/YYYY"},
    ranges: ranges
  }

  const config = Object.assign({}, defaultOptions, options)
  const dateRangeField = $(`#${id}`);
  dateRangeField.attr("autocomplete", "off");

  dateRangeField.daterangepicker(config, function(startDate, endDate) {
    dateRangeField.val(startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY'));
  }).on('apply.daterangepicker', function (ev, picker) {
    if(dateRangeField.val() == '') {
      dateRangeField.val(`${picker.startDate.format('MM/DD/YYYY')} - ${picker.endDate.format('MM/DD/YYYY')}`)
    }
  });
}


window.singleDatePicker = (id, options) => {
  runner(() => singleDatePicker(id, options))
}

window.singleDateTimePicker = (id, options) => {
  runner(() => singleDateTimePicker(id, options))
}

window.rangeDatePicker = (id, options, yearly = false) => {
  runner(() => rangeDatePicker(id, options, yearly))
}

window.setRangeDatePickerDateTimeValue = (id, value) => {
  $(`#${id}`).val(value ? moment(value, "YYYY-MM-DD HH:mm").format("MM/DD/YYYY hh:mm A") : value);
}

window.setDatePickerStartDate = (id, value) => {
  $(`#${id}`).data('daterangepicker').setStartDate('');
  $(`#${id}`).data('daterangepicker').setEndDate('');
  $(`#${id}`).data('daterangepicker').setStartDate(moment(value, "YYYY-MM-DD HH:mm").format("MM/DD/YYYY hh:mm A"));
}
