import Rails from 'rails-ujs';
import "./jquery"


import 'bootstrap';
import '../hyper'
import "daterangepicker"

import '../lib/parsley';
import "../app/user_selector"
import "../app/modals"
import "../app/file-uploader"
import "../lib/schema-validators"
import "../app/datepickers"
import "../app/reports"
import "../app/case_document"
import "../app/utils"
import "../app/simple_form_helpers"

import "../app/dashboard/recent_sessions"
import "../app/dashboard/cases_statistic"

import "../app/cases/cascade_selects"
import "../app/cases/case_fields_cascade_selects"
import "../app/cases/case_disposition_cascade_selects"
import "../app/cases/case_helpers"
import "../app/cases/order_type"

import "../app/sessions/locations"

import "../app/tenant/cascade_selects"

import "../app/templates/render_template"

import "../app/smart-select-btn-logic"

import "../app/datetime_helpers"

import 'select2'
import 'select2/dist/css/select2.css'
import "../app/filter_helper"

import html2canvas from './html2canvas'
import jsPDF from './jspdf.min'
import "@nathanvda/cocoon"
import 'jquery-validation';

import Turbolinks from 'turbolinks';

window.html2canvas = html2canvas
window.jsPDF = jsPDF

// Inject a header into turbolinks requests
document.addEventListener('turbolinks:request-start', function(event) {
  const xhr = event.data.xhr;
  xhr.setRequestHeader('X-Turbolinks-Nonce', $("script")[0].nonce);
});

// Because nonces can only be accessed via their IDL attribute
// after the page loads (for security reasons),
// they need to be read via JS and added back as
// normal attributes in the DOM before the page is cached
// otherwise on cache restoration visits, the nonces won’t be there!
document.addEventListener('turbolinks:before-cache', function() {
  $('script[nonce]').each(function(index, element) {
    $(element).attr('nonce', element.nonce);
  });
});

document.addEventListener("turbolinks:visit", function() {
  // Grab a reference to the script tag
  const script = document.getElementById('script')
  // Remove the script if it's present
  if (script) { script.remove() }
})

Rails.start();
Turbolinks.start();

// import * as ActiveStorage from 'activestorage';
// ActiveStorage.start();
