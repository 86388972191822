window.initSmartSelectAddBtn = function() {
    $(".smart-select-add-btn").off("click");

    $(".smart-select-add-btn").on("click", function (e) {
        if($(".select-pure__select--opened").length) {
            e.preventDefault();

            $(".select-pure__select").removeClass("select-pure__select--opened");

            setTimeout(() => {
                $(this)[0].click();
            }, 800)
        }
    });
}