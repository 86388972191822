import qs from "query-string";
import { keys } from "lodash";
import MonthView from "./views/month-view";
import WeekView from "./views/week-view";
import DayView from "./views/day-view";

const VIEW_MODE = {
    MONTH: 0,
    WEEK: 1,
    DAY: 2
};

class CalendarView {
    constructor(settings) {
        this.__settings = settings;
        this.__view = $(settings.selector);

        this.component = null;

        const calendarViewMode = qs.parse(location.search).calendar_view_mode;
        const presetViewMode = calendarViewMode && keys(VIEW_MODE).includes(calendarViewMode.toUpperCase());
        this.__updateViewMode(presetViewMode ? calendarViewMode : 'week');
    }

    __getViewMode() {
        return VIEW_MODE[this.selectedCalendarViewMode ? this.selectedCalendarViewMode.toUpperCase() : 'WEEK'];
    }

    __updateViewMode(viewMode) {
        this.selectedCalendarViewMode = viewMode;

        $("#schedule-title").text(`${viewMode === "day" ? "dai" : viewMode}ly schedule`);

        $(".calendar-filters a").not('#exportCalendarButton').each(function() {
            const [url, query] = $(this).attr('href').split('?')
            const href = qs.parse(query);
            href.calendar_view_mode = viewMode || 'week';
            $(this).attr('href', `${url}?${qs.stringify(href)}`);
        });

        const [url, query=""] = location.href.split('?');
        const search = qs.parse(query);
        search.calendar_view_mode = viewMode || 'week';
        history.pushState(history.state, "", `${url}?${qs.stringify(search)}`);

        if($('#resetSessionFiltersButton').length)
            this.__updateResetButtonValue();
    }

    __updateResetButtonValue() {
        let params = new URLSearchParams(window.location.search);
        params.delete("by_session_status[]");
        params.delete("by_county[]");
        params.delete("by_neutral[]");
        params.delete("by_owner[]");
        params.delete("by_refferal_source[]");
        let url = window.location.origin + window.location.pathname + "?" + params.toString();
        $('#resetSessionFiltersButton').attr('href', url)
    }

    componentDidRender() {
        $($('.calendar-view-mode-toggler').children().get(this.__getViewMode())).addClass('calendar-view-mode-toggler-selected');

        if(/by_session_status|by_county|by_neutral|by_owner|by_refferal_source/.test(location.search)) {
            $('#resetSessionFiltersButton').removeClass('d-none');

            this.__updateResetButtonValue();
        } else
            $('#resetSessionFiltersButton').addClass('d-none');

        const { defaultView} = this.__settings;

        if((this.selectedCalendarViewMode ? this.selectedCalendarViewMode : defaultView) === "week")
            $(".week-view-body")[0].scrollTo(0, 960);

        else if((this.selectedCalendarViewMode ? this.selectedCalendarViewMode : defaultView) === "day")
            $(".day-view-body")[0].scrollTo(0, 960);
    }

    __renderCalendar() {
        const viewObject = $("<div class=\"calendar-view\"></div>");

        viewObject.append(
            ` <div class="container-fluid calendar-view-header">
                <div class="row">
                    <div class="col-md-2 d-flex align-items-center">
                        <span class="fc-icon fc-icon-left-single-arrow clickable" id="prevDateAttribute"></span>
                        <span class="fc-icon fc-icon-right-single-arrow clickable" id="nextDateAttribute"></span>
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <h4 class="w-100 text-center calendar-date">
                            ${this.component.getDateLabel()}
                        </h4>
                    </div>
                    <div class="col-md-4 d-flex align-items-center calendar-view-mode-toggler d-flex">
                        <span class="flex-fill">Month</span>
                        <span class="flex-fill">Week</span>
                        <span class="flex-fill">Day</span>
                    </div>
                </div>
            </div>`
        );

        viewObject.append(this.component.render());

        this.__view.html(viewObject);

        this.initEvents();

        this.componentDidRender();
    };

    initEvents() {
        const { events, timeRange=[1,24] } = this.__settings;

        $('#prevDateAttribute').on('click', (e) => {
            this.component.prev();
            this.__renderCalendar();
        });

        $('#nextDateAttribute').on('click', (e) => {
            this.component.next();
            this.__renderCalendar();
        });

        $($('.calendar-view-mode-toggler').children().get(0)).on('click', () => {
            this.__updateViewMode('month');
            this.__settings.customDate = new Date();
            this.component = new MonthView(events, this.__settings);
            this.__renderCalendar();
        });

        $($('.calendar-view-mode-toggler').children().get(1)).on('click', () => {
            this.__updateViewMode('week');
            this.__settings.customDate = new Date();
            this.component = new WeekView(events, this.__settings, timeRange);
            this.__renderCalendar();
        });

        $($('.calendar-view-mode-toggler').children().get(2)).on('click', () => {
            this.__updateViewMode('day');
            this.__settings.customDate = new Date();
            this.component = new DayView(events, this.__settings, timeRange);
            this.__renderCalendar();
        });
    }

    render() {
        const { defaultView, events, timeRange=[1,24] } = this.__settings;

        switch(this.selectedCalendarViewMode ? this.selectedCalendarViewMode : defaultView) {
            case 'month':
                this.component = new MonthView(events, this.__settings);
                break;
            case 'week':
                this.component = new WeekView(events, this.__settings, timeRange);
                break;
            case 'day':
                this.component = new DayView(events, this.__settings, timeRange);
                break;
        }

        this.__renderCalendar();
    }
}

export default CalendarView;
