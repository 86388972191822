window.Juvenile = {
    Title: "Juvenile",
    Cascade: {
      "Children in Need of Services": {
        Title: "Children in Need of Services"
      },
      "Class A felony": {
        Title: "Class A felony"
      },
      "Class B felony": {
        Title: "Class B felony"
      },
      "Delinquency": {
        Title: "Delinquency"
      },
      "Dependency": {
        Title: "Dependency"
      },
      "Emancipation": {
        Title: "Emancipation"
      },
      "Not Designated": {
        Title: "Not Designated"
      },
      "Special Proceedings": {
        Title: "Special Proceedings"
      },
      "Termination of Parental Rights": {
        Title: "Termination of Parental Rights"
      },
      "Traffic": {
        Title: "Traffic"
      }
    }
}

window.Magistrate = {
  Title: "Magistrate",
  Cascade: {
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Civil Claims": {
          Title: "Civil Claims"
          // Cascade: CaseSubtypesWithPrices
        },
        "Dispossessories and Distress": {
          Title: "Dispossessories and Distress"
        },
        "Foreclosures and Attachments": {
          Title: "Foreclosures and Attachments"
        },
        "Garnishments": {
          Title: "Garnishments"
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
        }
      }
    },
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Ordinance Violations": {
          Title: "Ordinance Violations",
        },
        "Misdemeanors": {
          Title: "Misdemeanors",
          // Cascade: CascadeTextField
        }
      }
    }
  }
}

window.Superior = {
  Title: "Superior",
  Cascade: {
    "Domestic Relations": {
      Title: "Domestic Relations",
      Cascade: {
        "Adoption": {
          Title: "Adoption"
          // Cascade: CaseSubtypes
        },
        "Alimony": {
          Title: "Alimony"
          // Cascade: CaseSubtypes
        },
        "Annulment": {
          Title: "Annulment"
          // Cascade: CaseSubtypes
        },
        "Contempt": {
          Title: "Contempt"
          // Cascade: CaseSubtypes
        },
        "Contempt – Monetary": {
          Title: "Contempt – Monetary"
          // Cascade: CaseSubtypes
        },
        "Contempt – Non-Monetary": {
          Title: "Contempt – Non-Monetary"
          // Cascade: CaseSubtypes
        },
        "Divorce/ Dissolution/ Separate Maintenance": {
          Title: "Divorce/ Dissolution/ Separate Maintenance"
          // Cascade: CaseSubtypes
        },
        "Domestication of Foreign Judgement": {
          Title: "Domestication of Foreign Judgement"
          // Cascade: CaseSubtypes
        },
        "Grandparent Visitation/Custody":{
          Title: "Grandparent Visitation/Custody"
        },
        "Legitimation": {
          Title: "Legitimation"
          // Cascade: CaseSubtypes
        },
        "Modification": {
          Title: "Modification"
          // Cascade: CaseSubtypes
        },
        "Paternity": {
          Title: "Paternity"
          // Cascade: CaseSubtypes
        },
        "Support-IV-D": {
          Title: "Support-IV-D"
          // Cascade: CaseSubtypes
        },
        "Support-Private (non- IV-D)": {
          Title: "Support-Private (non- IV-D)"
          // Cascade: CaseSubtypes
        },
      }
    },
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Appeals": {
          Title: "Appeals"
          // Cascade: CaseSubtypes
        },
        "Contracts": {
          Title: "Contracts"
          // Cascade: CaseSubtypes
        },
        "Damages": {
          Title: "Damages"
          // Cascade: CaseSubtypes
        },
        "Declaratory Judgement": {
          Title: "Declaratory Judgement"
          // Cascade: CaseSubtypes
        },
        "Dispossessory/Distress": {
          Title: "Dispossessory/Distress"
          // Cascade: CaseSubtypes
        },
        "Garnishment": {
          Title: "Garnishment"
          // Cascade: CaseSubtypes
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
          // Cascade: CaseSubtypes
        },
        "Other General Civil": {
          Title: "Other General Civil"
          // Cascade: CaseSubtypes
        },
        "Personal Injury": {
          Title: "Personal Injury"
          // Cascade: CaseSubtypes
        },
        "Personal Property": {
          Title: "Personal Property"
          // Cascade: CaseSubtypes
        },
        "Real Property": {
          Title: "Real Property"
          // Cascade: CaseSubtypes
        },
        "Tort": {
          Title: "Tort"
          // Cascade: CaseSubtypesWithAdditionalCategories
        },
      }
    }
  }
}

window.Municipal = {
    Title: "Municipal",
    Cascade: {
      "General Civil": {
        Title: "General Civil",
        Cascade: {
          "Claims": {
            Title: "Claims"
            // Cascade: {
            //   "Seller": { Title: "Seller" },
            //   "Buyer": { Title: "Buyer" }
            // }
          },
          "Dispossessories and Distress": {
            Title: "Dispossessories and Distress"
          },
          "Garnishments": {
            Title: "Garnishments"
          },
          "Foreclosures and Attachments": {
            Title: "Foreclosures and Attachments"
          },
          "Non-Criminal Traffic Violations": {
            Title: "Non-Criminal Traffic Violations"
          }
        }
      },
      "Criminal": {
        Title: "Criminal",
        Cascade: {
          "Traffic": {
            Title: "Traffic",
            // Cascade: CascadeTextField
          },
          "Misdemeanors": {
            Title: "Misdemeanors",
            // Cascade: CascadeTextField
          },
          "Parking Violation": {
            Title: "Parking Violation"
          },
          "Ordinance": {
            Title: "Ordinance"
          }
        }
      }
    }
}

window.Probate = {
  Title: "Probate",
  Cascade: {
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Criminal Citations": {
          Title: "Criminal Citations"
        },
        "Traffic": {
          Title: "Traffic"
        }
      }
    },
    "General Probate": {
      Title: "General Probate",
      Cascade: {
        "Conservatorship": {
          Title: "Conservatorship"
        },
        "Estates": {
          Title: "Estates"
        },
        "Guardianship": {
          Title: "Guardianship"
        },
        "Trusts": {
          Title: "Trusts"
        }
      }
    },
    "Mental Health": {
      Title: "Mental Health",
      Cascade: {
        "Involuntary Treatment": {
          Title: "Involuntary Treatment"
        },
        "Order to Apprehend": {
          Title: "Order to Apprehend"
        },
        "Other Mental Health": {
          Title: "Other Mental Health"
        }
      }
    }
  }
}

window.State = {
  Title: "State",
  Cascade: {
    "Criminal": {
      Title: "Criminal",
      Cascade: {
        "Traffic": {
          Title: "Traffic",
          // Cascade: CascadeTextField
        }
      }
    },
    "General Civil": {
      Title: "General Civil",
      Cascade: {
        "Appeal": {
          Title: "Appeal"
          // Cascade: CaseSubtypes
        },
        "Contracts": {
          Title: "Contracts"
          // Cascade: CaseSubtypes
        },
        "Dispossessory/Distress": {
          Title: "Dispossessory/Distress"
          // Cascade: CaseSubtypes
        },
        "Garnishment": {
          Title: "Garnishment"
          // Cascade: CaseSubtypes
        },
        "Landlord/Tenant": {
          Title: "Landlord/Tenant"
          // Cascade: CaseSubtypes
        },
        "Other General Civil": {
          Title: "Other General Civil"
          // Cascade: CaseSubtypes
        },
        "Tort": {
          Title: "Tort"
          // Cascade: CaseSubtypesWithAdditionalCategories
        },
      }
    },
  }
}

window.DistrictAttorney = {
  Title: "District Attorney"
}

window.CivilCourt = {
  Title: "Civil Court"
}

window.Community = {
  Title: "Community"
}

window.ot_dropdowns_in_sequence = ['.ot_refferal_source', '.ot_case_category', '.ot_case_type']

window.ot_update_next_dropdown = function(selected_value='', select_to_be_updated, nested, ot_refferal_source, closest_nested_fields){
  selected_value = selected_value === null || selected_value === undefined ? '' : selected_value;
  
  dynamic_cascade = window[selected_value]

  if(nested && window[ot_refferal_source.val().split(' ').join('')].Cascade != undefined){
    dynamic_cascade = window[ot_refferal_source.val().split(' ').join('')].Cascade[selected_value]
  }
  
  select_to_be_updated.empty();
  closest_nested_fields.find('.ot_case_type').empty()
  let blank_option = $('<option>').val('').text('All');
  closest_nested_fields.find('.ot_case_type').append(blank_option);
  closest_nested_fields.find('.ot_case_type').prop('disabled', true)
  select_to_be_updated.append(blank_option);
  select_to_be_updated.prop('disabled', true)
  try{
    for (const key in dynamic_cascade.Cascade) {
      const option = $('<option>').val(key).text(dynamic_cascade.Cascade[key].Title);
      select_to_be_updated.append(option);
      select_to_be_updated.prop('disabled', false)
    }
    if(dynamic_cascade.Cascade == undefined){
      closest_nested_fields.find('.ot_case_type').empty()
      blank_option = $('<option>').val('').text('All');
      closest_nested_fields.find('.ot_case_type').append(blank_option);
      closest_nested_fields.find('.ot_case_type').prop('disabled', true)
    }
  } catch (err) {
    console.log('ot_catch: ' + err);
    closest_nested_fields.find('.ot_case_type').empty()
    blank_option = $('<option>').val('').text('All');
    closest_nested_fields.find('.ot_case_type').append(blank_option);
    closest_nested_fields.find('.ot_case_type').prop('disabled', true)

    if(!nested){
      closest_nested_fields.find('.ot_case_category').empty()
      blank_option = $('<option>').val('').text('All');
      closest_nested_fields.find('.ot_case_category').append(blank_option);
      closest_nested_fields.find('.ot_case_category').prop('disabled', true)

    }
  }
  if(closest_nested_fields.find('.ot_case_type option').length == 0){
    blank_option = $('<option>').val('').text('All');
    closest_nested_fields.find('.ot_case_type').append(blank_option);
    closest_nested_fields.find('.ot_case_type').prop('disabled', true)
  }
}

window.isBlank = function(value) {
  // Check for undefined or null
  if (value === undefined || value === null) {
    return true;
  }
  
  // Check for empty string
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  // Check for empty array or empty object
  if (Array.isArray(value) || typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  // If none of the above conditions are met, it's not blank
  return false;
}

window.updateArr = function(arr) {
  if (typeof arr === "string") {
    return JSON.parse(arr)
  } else {
    return arr
  }
}

window.ot_update_all_dropdown = function(){
  let nested_fields = $('#order_types').find('.nested-fields')
  console.log(nested_fields)
  nested_fields.each(function(id, obj){
    obj = $(obj)

    selected_value = obj.find('.ot_refferal_source').val()
    select_to_be_updated = obj.find('.ot_case_category')
    nested = false
    ot_refferal_source = obj.find('.ot_refferal_source')
    closest_nested_fields = obj
    ot_update_next_dropdown(selected_value, select_to_be_updated, nested, ot_refferal_source, closest_nested_fields)
    obj.find('.ot_case_category').select()
    obj.find('.ot_case_category').val(obj.find('.ot_case_category').attr('data-selected-value'));

    selected_value = obj.find('.ot_case_category').val()
    select_to_be_updated = obj.find('.ot_case_type')
    nested = true
    ot_refferal_source = obj.find('.ot_refferal_source')
    closest_nested_fields = obj
    ot_update_next_dropdown(selected_value, select_to_be_updated, nested, ot_refferal_source, closest_nested_fields)
    obj.find('.ot_case_type').select()
    obj.find('.ot_case_type').val(obj.find('.ot_case_type').attr('data-selected-value'));
  })
}

$(document).on('change', '.ot_refferal_source, .ot_case_category', function(){
  let closest_nested_fields = $(this).closest('.nested-fields')

  let ot_refferal_source = closest_nested_fields.find('.ot_refferal_source')

  let nested = $(this).hasClass('ot_case_category')

  let select_to_be_updated = closest_nested_fields.find($(this).attr('data-next'))

  let selected_value = $(this).val()

  ot_update_next_dropdown(selected_value, select_to_be_updated, nested, ot_refferal_source, closest_nested_fields)
})