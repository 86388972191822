import SelectPure from "select-pure";
import runner from "../lib/initializator"

const storeElement = (inputId, element) => {
  if (!window.UserSelectorStore) window.UserSelectorStore = {}

  window.UserSelectorStore[inputId] = element
}

const getElement = (inputId) => window.UserSelectorStore[inputId]

const SingleUserSelector = (inputId, collection, value) => {
  const containerId = `#${inputId}-container`
  $(containerId).empty()

  const renderHiddenInputs = (value) => {
    var input = $(`#${inputId}`);
    $(`#${inputId}`).val(value);
    if (input.is(':hidden')) input.trigger("change");
  };

  renderHiddenInputs(value)

  const elt = new SelectPure(containerId, {
        options: collection,
        autocomplete: true,
        value: value,
        multiple: false,
        icon: "close-icon",
        onChange: value => { renderHiddenInputs(value) },
    });

  return elt;
}

const MultipleUserSelector = (inputId, collection, values) => {
  const containerId = `#${inputId}-container`
  $(containerId).empty()

  const renderHiddenInputs = (values) => {
    const inputsContainer = $(`#${inputId}`).parent()

    const newElems = []

    values.forEach(value => {
      const newInput = $(`#${inputId}`).clone(true);
      newInput.val(value)

      newElems.push(newInput)
    });

    if (newElems.length > 0) {
      inputsContainer.empty()
      newElems.forEach(elem => elem.appendTo(inputsContainer))
    }
    else {
      // need to leave at least one input with epty value
      const newInput = $(`#${inputId}`).clone(true);
      newInput.val(undefined)

      inputsContainer.empty()
      newInput.appendTo(inputsContainer)
    }
  }

  renderHiddenInputs(values)

  const elt = new SelectPure(containerId, {
        options: collection,
        autocomplete: true,
        value: values,
        multiple: true,
        icon: "close-icon",
        onChange: values => renderHiddenInputs(values),
    });

  return elt;
}

const AddOptionToUserSelector = function(inputId, newOption, selectNewOption) {
  const element = getElement(inputId)
  const isMultiple = element._config.multiple;
  const options = element._config.options;
  let value = element._config.value;
  options.push(newOption)
  options.sort(compareValues('label'));

  if (selectNewOption)
    isMultiple ? value.push(newOption.value) : value = newOption.value

  const constructorMethod = isMultiple ? MultipleUserSelector : SingleUserSelector

  const newEement = constructorMethod(inputId, options, value)

  storeElement(inputId, newEement)
}

const compareValues = function(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

window.AddOptionToUserSelector = AddOptionToUserSelector

window.MultipleUserSelector = function(inputId, collection, values) {
  const run = () => {
    const element = MultipleUserSelector(inputId, collection, values)

    storeElement(inputId, element)
  }

  runner(run);
  window.initSmartSelectAddBtn();
}

window.SingleUserSelector = function(inputId, collection, values) {
  const run = () => {
    const element = SingleUserSelector(inputId, collection, values)

    storeElement(inputId, element)
  }

  runner(run);
  window.initSmartSelectAddBtn();
}