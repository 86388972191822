import moment from "moment"

import CalendarView from "./calendar/calendar-view";


/* on click on event */
const onEventClick = function (calEvent, jsEvent, view) {
  $.ajax({
    method: "GET",
    dataType: "script",
    url: calEvent.caseShowUrl,
  });
};


export const SessionsCalendar = function(events, date ,timeRange = [0,24]) {
  /*  Initialize the calendar  */

  const defaultEvents = []

  events.forEach(function(event) {
    let classNames = ['']

    if (event.domesticViolance)
      classNames.push("color-red")

    if (event.inAdrOffice)
      classNames.push("text-bold")

    if (event.case_satus == "In Process")
      classNames.push("session-in-progress")

    if (event.case_satus == "Disposed")
      classNames.push("session-disposed")

    if (event.case_satus == "On Hold")
      classNames.push("session-on-hold")
    defaultEvents.push({
      title: event.case_number,
      start: moment(event.start_datetime, 'YYYY-MM-DD hh-mm-ss')._d,
      end: moment(event.end_datetime, 'YYYY-MM-DD hh-mm-ss')._d,
      className: classNames.join(' '),
      caseStatus: event.case_satus || '',
      caseNumber: event.case_number,
      caseTitle: event.case_title,
      virtual: event.virtual,
      location: event.location,
      time: event.time,
      mediatorForSession: event.mediator_for_session,
      caseCounty: event.case_county,
      caseShowUrl: event.caseShowUrl,
      colorBy: event.color_by,
      sessionStatus: event.session_status,
      domesticViolence: event.domesticViolence,
    });
  });

  const calendar = new CalendarView({
    selector: '#calendar',
    defaultView: 'week',
    events: defaultEvents,
    timeRange: timeRange,
    customDate: date,
    onEventClick
  });

  calendar.render();
};
