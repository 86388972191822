import { saveAs } from 'file-saver';
import PizZip from "pizzip"
import PizZipUtils from "pizzip/utils/es6"
import docxtemplater from "docxtemplater"
import expressions from "angular-expressions";
import merge from "lodash/merge";

function angularParser(tag) {
    if (tag === '.') {
        return {
            get: function(s){ return s;}
        };
    }
    const expr = expressions.compile(
        tag.replace(/(’|‘)/g, "'").replace(/(“|”)/g, '"')
    );
    return {
        get: function(scope, context) {
            let obj = {};
            const scopeList = context.scopeList;
            const num = context.num;
            for (let i = 0, len = num + 1; i < len; i++) {
                obj = merge(obj, scopeList[i]);
            }
            const result = expr(scope, obj);

            return result || "";
        }
    };
}
function parser(tag) {
    // We write an exception to handle the tag "$pageBreakExceptLast"
    if (tag === "$pageBreakExceptLast") {
        return {
            get(scope, context) {
                const totalLength = context.scopePathLength[context.scopePathLength.length - 1];
                const index = context.scopePathItem[context.scopePathItem.length - 1];
                const isLast = index === totalLength - 1;
                if (!isLast) {
                    return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
                }
                else {
                    return '';
                }
            }
        }
    }
    // We use the angularParser as the default fallback
    // If you don't wish to use the angularParser,
    // you can use the default parser as documented here :
    // https://docxtemplater.readthedocs.io/en/latest/configuration.html#default-parser
    return angularParser(tag);
}

const loadFile = (url,callback) => {
  PizZipUtils.getBinaryContent(url,callback);
}

const generate = (url, data, output) => {
  loadFile(url,function(error,content){
      if (error) { throw error };

      // The error object contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
      function replaceErrors(key, value) {
          if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                  error[key] = value[key];
                  return error;
              }, {});
          }
          return value;
      }

      function errorHandler(error) {
          console.log(JSON.stringify({error: error}, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors.map(function (error) {
                  return error.properties.explanation;
              }).join("\n");
              console.log('errorMessages', errorMessages);
              alert("Template is invalid. Message" + errorMessages)
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
          }
          else {
            alert("Template is invalid.")
          }

          throw error;
      }

      var zip = new PizZip(content);
      var doc;
      try {
          doc=new docxtemplater(zip, {parser: parser, paragraphLoop: true, linebreaks: true});
      } catch(error) {
          // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
          errorHandler(error);
      }

      doc.setData(data);
      try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
      }
      catch (error) {
          // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
          errorHandler(error);
      }

      var out=doc.getZip().generate({
          type:"blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }) //Output the document using Data-URI
      saveAs(out, output)
  })
}

window.renderTemplate = (url, data, output) => {
  generate (url, data, output)
}