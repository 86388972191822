$(document).ready(() => {
    "use strict";

    $('.btn-collapse-filter').on('click', function () {
        if ($(this).hasClass('btn-collapse-filter-toggled')) {
            $(this).removeClass('btn-collapse-filter-toggled');
            $(this).children().get(1).classList.remove('d-none');
            $(this).children().get(0).classList.add('d-none');
        } else {
            $(this).addClass('btn-collapse-filter-toggled');
            $(this).children().get(0).classList.remove('d-none');
            $(this).children().get(1).classList.add('d-none');
        }

        const target = $(this).attr('target');

        $(target).collapse('toggle');
    });

    $('ul.filter-list li').on('click', function () {
      let self = this;
      let name = $(self).attr("name");
      let valueId = $(self).attr("value");
      $(this).parent().children().each(function () {
          if (self == this){
            if($(this).hasClass('selected')) {
              $(this).removeClass('selected');
              $(`#selected_${valueId.replace(/[/\\ ]/g,'')}`).remove();
            } else {
              $(this).addClass('selected');
              $(self).append(`<input name= "${name}" type="hidden" id="selected_${valueId.replace(/[/\\ ]/g,'')}" value="${valueId}">`);
            }
          }
      })
    });
});