import Calendar from "../calendar";
import { values } from "lodash";

import CalendarViewMode from "./base-view";

class DayView extends CalendarViewMode {
    constructor(events, settings, timeRange) {
        super(events, settings);
        this.__timeRange = timeRange;
    }

    __renderTimeColumn() {
        const column = [];
        const [fromTime, toTime] = this.__timeRange;

        for (let i = fromTime; i < 12 && i < toTime; i++)
            column.push(`<div class="cell cell-${i}">${i}:00 am</div>`);

        for (let i = fromTime < 12 ? 12 : fromTime; i < toTime; i++)
            column.push(`<div class="cell cell-${i}">${i % 12 || 12}.00 pm</div>`);

        return column;
    }

    __renderEventsColumn() {
        const [fromTime, toTime] = this.__timeRange;

        const column = [];

        for(let i = fromTime; i < toTime; i++)
            column.push($(`<div class="cell cell-${i}"></div>`));

        const today = this.calendar.today().day;

        const events = this.__events.filter(event => this.calendar.isToday(event.start, today));

        const groupedEvents = this.groupEventsByHour(events);

        values(groupedEvents).forEach(events => {
            column.splice(
                events[0].start.getHours() - fromTime,
                1,
                this.renderEvents(events, this.__getEventInfo, 0)
            );
        });
        for(let i = fromTime; i < toTime; i++)
            if(typeof column[i] == 'object')
                column[i].addClass(`cell-${i}`);

        return column;
    }

    __renderHeader() {
        this.calendar.saveDateInLocalStorage(this.calendar.d);
        const cells = [
            $('<div class="cell cell-header"></div>'),
            $(`<div class="cell cell-header"><h5 class="text-center">${this.calendar.getWeekName()}</h5></div>`),
        ];

        return cells;
    }

    __getEventInfo(event) {
        return `
            <span class="${event.domesticViolence ? "text-danger" : ""}">${event.title}</span>
            ${event.virtual == 'Virtual' ? `${event.virtual}<br/>` : ""}
        `;
    }

    __renderBodyWrapper() {
        const headerCells = this.__renderHeader();

        const columns = [
            $(`<div class="column-time"></div>`),
            $(`<div class="column-content"></div>`),
        ];

        columns[0].append(headerCells[0])
        columns[1].append(headerCells[1])

        const headerRow = $("<div class='day-view-body-wrapper-header'></div>")
        headerRow.append(columns)

        const viewObject = $(`<div class="day-view-body-wrapper"></div>`);
        viewObject.append(headerRow);

        return viewObject
    }

    __renderBody() {
        const columns = [
            this.__renderTimeColumn(),
            this.__renderEventsColumn()
        ];

        const viewObject = $(`<div class="day-view-body"></div>`);

        const columnsContent = [
            $(`<div class="column-time"></div>`),
            $(`<div class="column-content"></div>`),
        ];

        columnsContent[0].append(columns[0]);
        columnsContent[1].append(columns[1]);

        viewObject.append(columnsContent);

        return viewObject;
    }

    next() {
        this.calendar.changeDay(1);
    }

    prev() {
        this.calendar.changeDay(-1);
    }

    getDateLabel() {
        return `${this.calendar.getMonth().name} ${this.calendar.today().day}, ${this.calendar.d.getFullYear()}`;
    }

    render() {
        const viewObject = $(`<div class="day-view"></div>`);

        const bodyWrapper = this.__renderBodyWrapper();
        bodyWrapper.append(this.__renderBody());

        viewObject.append(bodyWrapper)

        return viewObject;
    }
}

export default DayView;