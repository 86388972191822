import Calendar from "../calendar";
import CalendarViewMode from "./base-view";

class MonthView extends CalendarViewMode {
    constructor(events, settings) {
        super(events, settings);
    }

    __renderHeader() {
        this.calendar.saveDateInLocalStorage(this.calendar.d);

        const days = [ "SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT" ];

        const cells = days.map(day => `<div class="cell">${day}</div>`);

        return `<div class="month-view-header">${cells.join('')}</div>`
    }

    __daysInMonth(year, month) {
        return new Date(year, month, 0);
    }

    __getOverMonthDays(startWeekDay, maxDay) {
        const calendarDaysCount = 42;
        let prevMonthLastDays, nextMonthFirstDays;

        let lastMonth = new Calendar(this.calendar.d);
        lastMonth.changeMonth(-1);

        if(startWeekDay > 1) {
            const lastDays = [...Array(lastMonth.daysInMonth())].map((_,i) => i+1);

            prevMonthLastDays = lastDays.slice(lastMonth.daysInMonth()-startWeekDay);
        }

        nextMonthFirstDays = calendarDaysCount - (prevMonthLastDays ? prevMonthLastDays.length : 0) - maxDay;
        nextMonthFirstDays = [...Array(nextMonthFirstDays)].map((_,i) => i);

        if(!prevMonthLastDays)
            prevMonthLastDays = [lastMonth.daysInMonth()];

        return { prevMonthLastDays, nextMonthFirstDays };
    }

    __getEventInfo(event) {
        return `
            <span class="display-case-name-session  ${event.domesticViolence ? "text-danger" : ""}">${event.title}</span>
            ${event.virtual == 'Virtual' ? `<br/>${event.virtual}<br/>` : ""}
        `;
    }

    __getDayEvents(day) {
        const events = this.__events.filter(event => this.calendar.isToday(event.start, day));

        return events.length ? this.renderEvents(events, this.__getEventInfo, this.calendar.getWeekDay()+1) : null;
    }

    __getDayContent(day, weekDayIndex, canSetDay, prevMonthLastDays, nextMonthFirstDays, size) {
        let dayContent;

        const isToday = this.calendar.isToday(Calendar.Today().d, day);

        if (canSetDay)
            dayContent = $(`<div class='day-label${isToday ? " day-label-selected" : ""}'>${day}</div>`);
        else {
            if (day === 1)
                dayContent = $(`<div class='day-label'>${prevMonthLastDays && prevMonthLastDays[weekDayIndex]}</div>`);
            else
                dayContent = $(`<div class='day-label'>${nextMonthFirstDays && (nextMonthFirstDays.shift() + 1)}</div>`);
        }

        const dayEvents = size < 1 ? this.__getDayEvents(day) : $("")

        return [dayContent, dayEvents];
    }

    __renderBody() {
        const rows = [];
        const startWeekDay = this.calendar.getWeekDay();
        const maxDay = this.calendar.daysInMonth();
        let canSetDay = false;
        let dayNumber = 1;

        const { prevMonthLastDays, nextMonthFirstDays } = this.__getOverMonthDays(startWeekDay, maxDay);
        let sizeOfPrev = prevMonthLastDays.length;

        for(let i = 0; i < 6; i ++) {
            const row = [];

            for(let j = 0; j < 7; j++) {
                if(dayNumber > maxDay)
                    canSetDay = false;
                
                if(i === 0 && j === startWeekDay)
                    canSetDay = true;

                const dayContent = this.__getDayContent(dayNumber, j, canSetDay, prevMonthLastDays, nextMonthFirstDays, sizeOfPrev);
                
                const isToday = this.calendar.isToday(Calendar.Today().d, dayNumber);
                
                const rowContent = $(`<div class="cell ${isToday ? "cell-today" : ""} ${!canSetDay ? " cell-disabled" : ""}"></div>`);

                rowContent.append(dayContent);
                row.push(rowContent);

                if(canSetDay) dayNumber++;
                sizeOfPrev--;
            }

            const _row = $('<div class="month-view-body-row"></div>');
            _row.append(row);
            rows.push(_row);
        }

        const viewObject = $(`<div class="month-view-body"></div>`);

        viewObject.append(rows);

        return viewObject;
    }

    next() {
        this.calendar.changeMonth(1);
    }

    prev() {
        this.calendar.changeMonth(-1);
    }

    getDateLabel() {
        return `${this.calendar.getMonth().name} ${this.calendar.d.getFullYear()}`;
    }

    render() {
        const viewObject = $(`<div class="month-view">${this.__renderHeader()}</div>`);

        viewObject.append(this.__renderBody());

        return viewObject;
    }
}

export default MonthView;