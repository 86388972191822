const MONTHS = [
  "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
];

const WEEK_DAYS = [ "SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY" ];

class Calendar {
  constructor (date,startWeekDate) {
    if(localStorage.getItem('savedDate') && localStorage.getItem('savedDate').length > 0 ){
      var loc = localStorage.getItem('savedDate')
      date = new Date(loc)
    }
    if(startWeekDate){
      date = startWeekDate
    }
      this.d = date || new Date();
  }

  static formatAMPM(date=new Date()) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}${ampm}`;
  }

  isToday(date, currentDay) {
    return this.d.getFullYear() === date.getFullYear() &&
        this.d.getMonth() === date.getMonth() &&
        currentDay === date.getDate();
  }

  isEventTodayForWeek(date, day, month, year) {
    return year === date.getFullYear() &&
        month === date.getMonth() &&
        day === date.getDate();
  }

  static Today() {
    const calendar = new Calendar();

    return { d: calendar.d, day: calendar.d.getDate(), weekDay: calendar.d.getDay() };
  }

  today() {
    return { day: this.d.getDate(), weekDay: this.d.getDay() };
  }

  changeDay(step) {
    this.d = new Date(this.d.getFullYear(), this.d.getMonth(), this.d.getDate()+step);
  }

  changeWeek(step) {
    this.d = new Date(this.d.getFullYear(), this.d.getMonth(), this.d.getDate()+step*7);
  }

  changeMonth(step) {
    this.d = new Date(this.d.getFullYear(), this.d.getMonth()+step, 1);
  }

  changeYear(step) {
    this.d = new Date(this.d.getFullYear()+step, this.d.getMonth(), 1);
  }

  getWeekStartDate() {
    const today = this.today();

    const startDate = new Calendar(this.d);
    startDate.changeDay(-today.weekDay);

    return startDate;
  }

  getMonthEndDate(week_start_date){
    var current_month_end_date = new Date(this.d.getFullYear(), this.d.getMonth(), 0);
    if (week_start_date > current_month_end_date.getDate()){
      return -1;
    } else {
      return week_start_date;
    }
  }

  getMonth() {
    return {
      number: this.d.getMonth() + 1,
      name: MONTHS[this.d.getMonth()]
    }
  }

  getWeekDay() {
    return new Date(this.d.getFullYear(), this.d.getMonth(), 1).getDay();
  }

  getWeekName() {
    return  WEEK_DAYS[this.d.getDay()]
  }

  daysInMonth() {
    return new Date(this.d.getFullYear(), this.d.getMonth()+1, 0).getDate();
  }

  saveDateInLocalStorage = function(days) {
    localStorage.setItem('savedDate', "")
    $(document).on('click', '.select-filter', function () {
      localStorage.setItem('savedDate', days)
    })
  }
}

export default Calendar;
