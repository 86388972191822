import { CascadeSelects } from "../../lib/cascaded_selects"

const ADR_PROCESS = {
  CascadeSelectorContainerId: "adr_process_container",
  CascadeSelectorName: "case[adr_process]",
  CaseSelectorId: "case_adr_process",
  CaseInputLabel: "Adr process"
}

const ADDR_PROCESS_SUBTYPE = {
  CascadeSelectorContainerId: "adr_process_subtype_container",
  CascadeSelectorName: "case[adr_process_subtype]",
  CaseSelectorId: "case_adr_process_subtype",
  CaseInputLabel: "Adr process subtype"
}

const CASCADE_LEVELS = [ADR_PROCESS, ADDR_PROCESS_SUBTYPE]

const ArbitrationBinding = {
  Title: "Arbitration - Binding",
  // Cascade: {
  //   "Binding": { Title: "Binding" },
  //   "Non-Binding": {Title: "Non-Binding"},
  // }
}

const ArbitrationNonBinding = {
  Title: "Arbitration - Non-binding"
}

const CaseEvaluation = {
  Title: "Case Evaluation"
}

const EarlyNeutralEvaluation = {
  Title: "Early Neutral Evaluation"
}

const Mediation = {
  Title: "Mediation",
  // Cascade: {
  //   "Domestic Relations": { Title: "Domestic Relations" },
  //   "General": { Title: "General" },
  //   "Juvenile Delinquency": { Title: "Juvenile Delinquency" },
  //   "Juvenile Dependency": { Title: "Juvenile Dependency" },
  //   "Victim/Offender": { Title: "Victim/Offender" }
  // }
}

const Jhsc = {
  Title: "JHSC"
}

const LateCaseEvaluation = {
  Title: "Late Case Evaluation"
}

const SummaryJuryTrials = {
  Title: "Summary Jury Trials"
}

const MediationCalendarCall = {
  Title: "Mediation – Calendar Call"
}

const renderCascade = (elemConfig, rootForm) => {
  const rawHtml = `<div class="form-group select required case_adr_process">
    <label class="select required" for="case_adr_process">
      ${elemConfig.CaseInputLabel}
    </label>
    <select class="select required form-control" required="required" aria-required="true" name="${elemConfig.CascadeSelectorName}" id="${elemConfig.CaseSelectorId}">
    </select>
  </div>`

  const template = document.createElement('template');
  template.innerHTML = rawHtml.trim();

  const cascadeNodeContainer = rootForm ? rootForm.find(`#${elemConfig.CascadeSelectorContainerId}`)[0] : document.getElementById(elemConfig.CascadeSelectorContainerId);
  const node = template.content.firstChild;

  while (cascadeNodeContainer.firstChild) {
    cascadeNodeContainer.removeChild(cascadeNodeContainer.firstChild);
  }

  cascadeNodeContainer.append(node)

  const selectNode = rootForm ? rootForm.find(`#${elemConfig.CaseSelectorId}`)[0] : document.getElementById(elemConfig.CaseSelectorId);

  return selectNode;
}


const Main = {
  CascadeLevels: CASCADE_LEVELS,
  renderCascade: renderCascade,
  Cascade: {
    "Arbitration - Binding": ArbitrationBinding,
    "Arbitration - Non-binding": ArbitrationNonBinding,
    "Case Evaluation": CaseEvaluation,
    "Early Neutral Evaluation": EarlyNeutralEvaluation,
    "JHSC": Jhsc,
    "Late Case Evaluation": LateCaseEvaluation,
    "Mediation": Mediation,
    "Mediation – Calendar Call": MediationCalendarCall,
    "Summary Jury Trials": SummaryJuryTrials
  }
}

window.initCaseFieldsCascadeFields = function(selectedValues, elem_required) {
  document.addEventListener('DOMContentLoaded', function(){
    CascadeSelects(Main, "case_adr_process", selectedValues, elem_required)
  });
}
