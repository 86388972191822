import { SessionsCalendar } from "./calendar_app"
import runner from "../../lib/initializator"

window.initRecentSessionsCalendar = function(events) {
  runner(() => SessionsCalendar(events))
}

window.reInitRecentSessionsCalendar = function(events, date, timeRange) {
  runner(() => SessionsCalendar(events, date, timeRange))
}
