// import * as WebDataRocks from "webdatarocks";
import * as FlexMonster from 'flexmonster';
// import "webdatarocks/webdatarocks.min.css";
import 'flexmonster/flexmonster.css';
import runner from  "../../lib/initializator";
import moment from "moment";

function prepareReportConfig(reportSchema) {
  reportSchema.options.dateTimePattern = "MM/DD/YYYY";
  reportSchema.options.grid.showHeaders = false;
  // reportSchema.slice.expands = { expandAll: true };

  return reportSchema;
}

function exportHandler(params) {
  window.webdatarocksPligin.exportTo(params.type, params.config)
}

function customizeCellFunction(cell, data) {
  if (!(data.hierarchy && data.hierarchy.uniqueName == "Case URL")) return;

  let newText =
      `<a href='${data.escapedLabel}' target='_blank' onclick='preventExpand(event)'>View</a>`;

  if (data.type == "value" && (data.member || data.isDrillThrough)) {
    cell.style['z-index'] = 1;
    cell.style['text-decoration'] = 'underline';
    cell.text = newText;
  }
}


window.initAdminReportPivotTable = (params = {}) => {
  const containerId = "reports-container";

  const reportOptions = params.reportOptions;
  const saveUrl       = params.saveUrl;
  const filterParams  = JSON.parse(params.filterParams);

  var saveHandler = function() {
    $.ajax({
      method: "GET",
      url: saveUrl,
      data: { report_template: filterParams, },
    });
  };

  var customizeToolbar = function(toolbar) {
    var tabs = toolbar.getTabs();
    toolbar.getTabs = function() {
      const expectedTabIds = ["fm-tab-save", "fm-tab-export", "fm-tab-format",
                              "fm-tab-options", "fm-tab-fields", "fm-tab-fullscreen"];
      tabs[3].menu[1].handler = exportHandler;
      tabs[3].menu[1].args = {
        type: 'html',
        config: {
          filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
        }
      }
      tabs[3].menu[2].handler = exportHandler;
      tabs[3].menu[2].args = {
        type: 'csv',
        config: {
          filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
        }
      }
      tabs[3].menu[3].handler = exportHandler;
      tabs[3].menu[3].args = {
        type: 'excel',
        config: {
          filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`,
        }
      }
      tabs[3].menu[4].handler = exportHandler;
      tabs[3].menu[4].args = {
        type: 'image',
        config: {
          filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
        }
      }
      tabs[3].menu[5].handler = exportHandler;
      tabs[3].menu[5].args = {
        type: 'pdf',
        config: {
          filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
        }
      }
      return tabs.filter(tab => expectedTabIds.includes(tab.id));
    };

    var saveTab = toolbar.getTabs().find(tab => tab.id == "fm-tab-save");
    saveTab.handler = saveHandler;
  };

  const config = {
    toolbar: true,
    container: document.getElementById(containerId),
    report: prepareReportConfig(JSON.parse(reportOptions)),
    beforetoolbarcreated: customizeToolbar,
    customizeCell: customizeCellFunction,
    licenseKey: params.licenseKey,
  };

  console.log(config);

  window.webdatarocksPligin = new FlexMonster(config);

  window.webdatarocksPligin.on("reportcomplete", () => {
    window.webdatarocksPligin.off("reportcomplete");
    subscriptionManager();
    window.webdatarocksPligin.refresh();
  });
  window.webdatarocksPligin.on("reportchange", subscriptionManager);

  runner(() => {
    window.webdatarocksPligin;
  });
};

window.initReportPivotTable = (params, saveHandler) => {
  const reportOptions = params.reportOptions
  const containerId = "reports-container";

  var customizeToolbar = function(toolbar) {
    var tabs = toolbar.getTabs();

    tabs[3].menu[1].handler = exportHandler;
    tabs[3].menu[1].args = {
      type: 'html',
      config: {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      }
    }
    tabs[3].menu[2].handler = exportHandler;
    tabs[3].menu[2].args = {
      type: 'csv',
      config: {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      }
    }
    tabs[3].menu[3].handler = exportHandler;
    tabs[3].menu[3].args = {
      type: 'excel',
      config: {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`,
      }
    }
    tabs[3].menu[4].handler = exportHandler;
    tabs[3].menu[4].args = {
      type: 'image',
      config: {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      }
    }
    tabs[3].menu[5].handler = exportHandler;
    tabs[3].menu[5].args = {
      type: 'pdf',
      config: {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      }
    }

    toolbar.getTabs = function() {
      const expectedTabIds = ["fm-tab-export", "fm-tab-fullscreen"];
      return tabs.filter(tab => expectedTabIds.includes(tab.id));
    };
  };

  const config = {
    toolbar: true,
    container: document.getElementById(containerId),
    report: prepareReportConfig(JSON.parse(reportOptions)),
    customizeCell: customizeCellFunction,
    beforetoolbarcreated: customizeToolbar,
    licenseKey: params.licenseKey,
  };

  window.webdatarocksPligin = new FlexMonster(config);

  window.webdatarocksPligin.on("reportcomplete", () => {
    window.webdatarocksPligin.off("reportcomplete");
    subscriptionManager();
    window.webdatarocksPligin.refresh();
  });
  window.webdatarocksPligin.on("reportchange", subscriptionManager);

  runner(() => window.webdatarocksPligin);
};

function subscriptionManager() {
  window.webdatarocksPligin.off("reportchange");
  window.webdatarocksPligin.on("reportchange", () => {
    window.webdatarocksPligin.off("reportchange");
    window.webdatarocksPligin.on("reportchange", subscriptionManager);
  });
  window.webdatarocksPligin.on("aftergriddraw", () => {
    window.webdatarocksPligin.off("aftergriddraw");
		adjustTableSizes();
  });
}

function adjustTableSizes() {
  let cells = document.querySelectorAll(".fm-cell");
  let highCells = [...cells].filter(cell => cell.clientHeight < cell.scrollHeight);
  let tableSizes = {
    rows: [],
    columns: []
  };
  highCells.forEach(cell => {
    tableSizes.rows.push({
      idx: +cell.getAttribute("data-r"),
      height: cell.scrollHeight
    });
    tableSizes.columns.push({
      idx: +cell.getAttribute("data-c"),
      width: cell.clientWidth
    });
  });
  window.webdatarocksPligin.setTableSizes(tableSizes);
}

function getFormattedDate() {
  return moment().format('MMDDYYYYHHmmss');
}

window.customizeExportToJSONReportPivot = (iconPath, downloadPath) => {
  setTimeout(() => {
    const exportToJson = $(`<li id="fm-tab-export-json"><a href="javascript:void(0)"><div class="fm-svg-icon"><img style="width: 22px; margin-left: 7px; margin-top: 5px;" src="${iconPath}"/></div><span>To JSON</span></a></li>`);

    exportToJson.on('click', function () {
      location.replace(`${downloadPath}${window.webdatarocksTitle ? ('?title=' + window.webdatarocksTitle) : ''}`);
    });

    $("#fm-tab-export-print").after(exportToJson);
  }, 1000);
}

window.customizeExportToReportPivot = () => {
  setTimeout(() => {
    const exportToPdf = $(`<li id="fm-tab-export-pdf"><a href="javascript:void(0)"><div class="fm-svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M17.2 17.1L17.2 17.1C17.3 17.1 17.3 17.1 17.2 17.1c0.1-0.5 0.2-0.7 0.2-1V15.8c0.1-0.6 0.1-1 0-1.1 0 0 0 0 0-0.1l-0.1-0.1 0 0 0 0c0 0 0 0.1-0.1 0.1C16.9 15.2 16.9 16 17.2 17.1L17.2 17.1zM13.8 24.8c-0.2 0.1-0.4 0.2-0.6 0.3 -0.8 0.7-1.3 1.5-1.5 1.8l0 0 0 0 0 0C12.5 26.9 13.1 26.2 13.8 24.8 13.9 24.8 13.9 24.8 13.8 24.8 13.9 24.8 13.8 24.8 13.8 24.8zM24.1 23.1c-0.1-0.1-0.6-0.5-2.1-0.5 -0.1 0-0.1 0-0.2 0l0 0c0 0 0 0 0 0.1 0.8 0.3 1.6 0.6 2.1 0.6 0.1 0 0.1 0 0.2 0l0 0h0.1c0 0 0 0 0-0.1l0 0C24.2 23.3 24.1 23.3 24.1 23.1zM24.6 24c-0.2 0.1-0.6 0.2-1 0.2 -0.9 0-2.2-0.2-3.4-0.8 -1.9 0.2-3.4 0.5-4.5 0.9 -0.1 0-0.1 0-0.2 0.1 -1.3 2.4-2.5 3.5-3.4 3.5 -0.2 0-0.3 0-0.4-0.1l-0.6-0.3v-0.1c-0.1-0.2-0.1-0.3-0.1-0.6 0.1-0.6 0.8-1.6 2.1-2.4 0.2-0.1 0.6-0.3 1-0.6 0.3-0.6 0.7-1.2 1.1-2 0.6-1.1 0.9-2.3 1.2-3.3l0 0c-0.4-1.4-0.7-2.1-0.2-3.7 0.1-0.5 0.4-0.9 0.9-0.9h0.2c0.2 0 0.4 0.1 0.7 0.2 0.8 0.8 0.4 2.6 0 4.1 0 0.1 0 0.1 0 0.1 0.4 1.2 1.1 2.3 1.8 2.9 0.3 0.2 0.6 0.5 1 0.7 0.6 0 1-0.1 1.5-0.1 1.3 0 2.2 0.2 2.6 0.8 0.1 0.2 0.1 0.5 0.1 0.7C24.9 23.5 24.8 23.8 24.6 24zM17.3 19.6c-0.2 0.8-0.7 1.7-1.1 2.7 -0.2 0.5-0.4 0.8-0.7 1.2h0.1 0.1l0 0c1.5-0.6 2.8-0.9 3.7-1 -0.2-0.1-0.3-0.2-0.4-0.3C18.4 21.6 17.7 20.7 17.3 19.6z"></path><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"></path></svg></div><span>To PDF</span></a></li>`);
    const exportToExcel = $(`<li id="fm-tab-export-excel"><a href="javascript:void(0)"><div class="fm-svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M24.4 15h-3.8L18 18.6 15.3 15h-3.8l4.5 5.2L11 27h7.3L18 25h-2l2-3L21.1 27H25l-5.1-6.8L24.4 15z"></path><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"></path></svg></div><span>To Excel</span></a></li>`);
    const exportToHtml = $(`<li id="fm-tab-export-html"><a href="javascript:void(0)"><div class="fm-svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path d="M25.7 20.8l-2.3-2.5c-0.2-0.2-0.4-0.3-0.6-0.3 -0.2 0-0.4 0.1-0.6 0.3 -0.3 0.4-0.3 1 0 1.4l1.7 1.9 -1.7 1.9c-0.2 0.2-0.3 0.4-0.3 0.7 0 0.3 0.1 0.5 0.3 0.7 0.2 0.2 0.4 0.3 0.6 0.3 0.2 0 0.4-0.1 0.6-0.3l2.3-2.5C26.1 21.8 26.1 21.2 25.7 20.8z"></path><path d="M14 24c0-0.3-0.1-0.5-0.3-0.7l-1.7-1.9 1.7-1.9c0.3-0.4 0.3-1 0-1.4 -0.2-0.2-0.4-0.3-0.6-0.3 -0.2 0-0.4 0.1-0.6 0.3l-2.3 2.5c-0.3 0.4-0.3 1 0 1.4l2.3 2.5c0.2 0.2 0.4 0.3 0.6 0.3 0.2 0 0.4-0.1 0.6-0.3C13.9 24.5 14 24.3 14 24z"></path><path d="M20.4 15.1c-0.1 0-0.2-0.1-0.3-0.1 -0.4 0-0.8 0.3-0.9 0.6l-4.1 11.1c-0.1 0.2-0.1 0.5 0 0.7 0.1 0.2 0.3 0.4 0.5 0.5C15.7 28 15.8 28 16 28c0.4 0 0.8-0.3 0.9-0.6l4.1-11.1C21.1 15.8 20.9 15.2 20.4 15.1z"></path><path d="M23 4H7v28h22V11L23 4zM8 31V5h14v7h6v19H8L8 31z"></path></svg></div><span>To HTML</span></a></li>`);

    exportToPdf.on('click', function () {
      window.webdatarocksPligin.exportTo('pdf', {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      })
    });

    exportToExcel.on('click', function () {
      window.webdatarocksPligin.exportTo('excel', {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      })
    });

    exportToHtml.on('click', function () {
      window.webdatarocksPligin.exportTo('html', {
        filename: `${window.location.host.split('.')[0]}-${$('#filter_report_type').val()}-${getFormattedDate()}`
      })
    });

    $("#fm-tab-export-html").remove();
    $("#fm-tab-export-excel").remove();
    $("#fm-tab-export-pdf").remove();

    $("#fm-tab-export-print").after(exportToPdf);
    $("#fm-tab-export-print").after(exportToExcel);
    $("#fm-tab-export-print").after(exportToHtml);
  }, 1000);

}

window.reRenderDateRangePicker = function(){
  if($('#filter_report_type').val() == 'adr_cases_monthly'){
    rangeDatePicker("filter_date_range", {}, true);
  } else {
    rangeDatePicker("filter_date_range");
  }
  rangeDatePicker("filter_date_range2");
  $('#filter_report_type').change(function(){
    if($('#filter_report_type').val() == 'adr_cases_monthly'){
      rangeDatePicker("filter_date_range", {}, true);
    } else {
      rangeDatePicker("filter_date_range");
    }
    rangeDatePicker("filter_date_range2");
  });
}
