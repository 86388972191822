import validate from "validate.js";

const schema = {
    name: { presence: true },
    report_type: { presence: true },
    table_type: { presence: true },
    data: { presence: true },
};

export default function(json) {
    const validationErrors = validate(json, schema, {format:"flat"});

    return validationErrors === undefined;
}
