import moment from "moment";

const hours = new Date().getTimezoneOffset() / 60;
const onlyHours = Math.floor(hours);
const minutes = (hours - onlyHours) * 60;
const onlyMinutes = Math.round(minutes);

function getFixed(value) {
    const date = new Date(value);
    date.setHours(date.getHours() - onlyHours);
    date.setMinutes(date.getMinutes() - onlyMinutes);
    return moment(date).format("lll");
}

function fixAll(selector) {
    $.each($(selector), function() {
        const $holder = $(this);
        if(!$holder.hasClass("__tz-fixed") && !$holder.hasClass("reverse-tz")) {
            const date = new Date($holder.text());
            date.setHours(date.getHours() - onlyHours);
            date.setMinutes(date.getMinutes() - onlyMinutes);
            $holder.text(moment(date).format("lll"));
            $holder.addClass("__tz-fixed");
        }
    })
}

export function updateTime(thisInput) {
    $(thisInput).text(moment(new Date()).format("lll"))
}

window.tz = {
    fixAll,
    getFixed
};

window.tz.fixAll(".d-tz");