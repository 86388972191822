function isTextField  (elemConfig) {
  return elemConfig && elemConfig.Cascade && Array.isArray(elemConfig.Cascade) && elemConfig.Cascade[0].fieldType === "text";
}

class CascadedSelects {
  constructor(rootNode, elem_required) {
    this.rootNode = rootNode;
    this.elem_required = elem_required;

    this.rootForm = $(rootNode).parent();

    while(this.rootForm.prop("tagName") !== 'FORM')
      this.rootForm = this.rootForm.parent();
  }

  BuildAndInsertOptionsToNode (Node, options) {
    var str = "<option></option>"
    options.forEach(element => {
      str += `<option value="${element.value}">${element.title}</option>`
    });

    Node.innerHTML = str;
  }

  mapConfigToOptions (config) {
    const result = []

    for (const [value, obj] of Object.entries(config)) {
      result.push({value: value, title: obj.Title})
    }

    return result;
  }

  insertToNode (containerNode, node) {
    while (containerNode.firstChild) {
      containerNode.removeChild(containerNode.firstChild);
    }

    containerNode.append(node)
  }

  RecursiveConfigExplorer (callback, parentElemConfig, level) {
    if (!level) level = 0

    if (parentElemConfig.Cascade) {
      for (const [value, elemConfig] of Object.entries(parentElemConfig.Cascade)) {
        callback(value, elemConfig, parentElemConfig, level + 1)

        this.RecursiveConfigExplorer(callback, elemConfig, level + 1)
      }
    }
  }

  RecursiveConfigMapper (callback, parentElemConfig, level) {
    if (!level) level = 0
    let result = parentElemConfig

    if (level === 0) {
      result = Object.assign(result, callback(undefined, result, 0))
    }

    if (parentElemConfig.Cascade) {
      for (const [value, elemConfig] of Object.entries(parentElemConfig.Cascade)) {
        const updatedElemConfig = callback(value, elemConfig, level + 1)

        result.Cascade[value] = this.RecursiveConfigMapper(callback, updatedElemConfig, level + 1)
      }
    }

    return result;
  }

  UnrenderAndReset (parentElemConfig) {
    let allInputs = this.rootForm
      .find("[id^=case_]")
      .toArray();

    const rightInputId = this.rootForm.find('#case_judge_id-container').length ? "case_judge_id-container" : "case_case_additional_category";

    const sliceRange = [
      allInputs.findIndex(input => $(input).attr('id') === "case_refferal_source"),
      allInputs.findIndex(input => $(input).attr('id') === rightInputId)
    ];

    allInputs = allInputs.slice(sliceRange[0]+1, sliceRange[1] >= 0 ? sliceRange[1]+1 : Number.MAX_SAFE_INTEGER);

    allInputs = allInputs.filter(input => $(input).prop("tagName") === "INPUT" || $(input).prop("tagName") === "SELECT");

    const thisIndex = allInputs.findIndex(input => input.id === parentElemConfig.CaseSelectorId);

    if (!(parentElemConfig.CaseSelectorId == "case_disposition_status" || parentElemConfig.CaseSelectorId == "case_adr_process" || parentElemConfig.CaseSelectorId == "case_disposition_type" || parentElemConfig.CaseSelectorId == "case_disposition_reason")) {
      allInputs.slice(thisIndex+1).forEach(input => {
        if (input.type !== "hidden") {
          const resetInput = document.createElement( 'input' );
          resetInput.type = "hidden"
          resetInput.value = ""
          resetInput.name = input.name;

          const containerNode = $(input).parent()[0];

          this.insertToNode(containerNode, resetInput)
        }
      });

      if($('#case_issues_offenses option').length == 0 || $('#issues_offenses_case').hasClass('d-none') ) {
        initCaseIssuesOffensesSelect();
      }
    }
  }

  AttachHandlerToNode (Node, config) {
    const self = this;
    Node.onchange = function(e) {
      var targ;
      if (!e) var e = window.event;
      if (e.target) targ = e.target;
      else if (e.srcElement) targ = e.srcElement;
      if (targ.nodeType == 3) // defeat Safari bug
        targ = targ.parentNode;

      self.OnValueSelect(config, config.Cascade[targ.value], isTextField(config.Cascade[targ.value]) ? "" : targ.value);
    };
  }

  OnValueSelect (parentElemConfig, elemConfig, valueItem) {
    this.UnrenderAndReset(parentElemConfig);
    let cascadeNode;

    if (elemConfig && elemConfig.Cascade) {
      const cascadeNodeContainer = this.rootForm.find(`#${elemConfig.CascadeSelectorContainerId}`)[0];

      if (elemConfig.renderCascade) {
        cascadeNode = elemConfig.renderCascade(elemConfig, this.rootForm, valueItem, this.elem_required)
      }
      else {
        cascadeNode = document.createElement( 'select' );
        cascadeNode.name = elemConfig.CascadeSelectorName;

        this.insertToNode(cascadeNodeContainer, cascadeNode)
      }

      if(!isTextField(elemConfig)) {
        this.BuildAndInsertOptionsToNode(cascadeNode, this.mapConfigToOptions(elemConfig.Cascade));
        this.AttachHandlerToNode(cascadeNode, elemConfig);
      }
    }

    if (elemConfig && elemConfig.AfterCascadeRender) {
      return elemConfig.AfterCascadeRender(elemConfig)
    }

    return cascadeNode;
  }

  SelectValues (Node, config, values) {
    if (values.length === 0) return;

    const valueItem = values.shift();
    const value = valueItem.constructor === Object ? valueItem.value : valueItem

    if (Array.isArray(value)) {
      for (var i = 0; i < Node.options.length; i++)
      {
        Node.options[i].selected = value.indexOf(Node.options[i].value) > -1
      }
    }
    else {
      Node.value = value;
    }

    if (config.Cascade) {
      const nextNode = this.OnValueSelect(config, config.Cascade[value], isTextField(config.Cascade[value]) ? values[0] : valueItem);

      if (nextNode)
        this.SelectValues(nextNode, config.Cascade[value], values)
    }

  }

  BuildAndPrepareConfig (settings) {
    const cascade_levels = settings.CascadeLevels;

    return this.RecursiveConfigMapper((value, elemConfig, level) => {
      const levelSettings = cascade_levels[level]
      return Object.assign({}, levelSettings, { renderCascade: settings.renderCascade }, elemConfig);
    }, settings)
  }

  render(settings, selectedValues) {
    const config = this.BuildAndPrepareConfig(settings);

    this.BuildAndInsertOptionsToNode(this.rootNode, this.mapConfigToOptions(config.Cascade));
    this.AttachHandlerToNode(this.rootNode, config);

    this.SelectValues(this.rootNode, config, selectedValues);
  }
}

export const CascadeSelects = function(settings, rootNodeId, selectedValues, elem_required=false) {
  const rootNodes = Array.prototype.slice.call(document.querySelectorAll(`#${rootNodeId}`));
  if(rootNodeId === 'case_disposition_status') {
    rootNodes
      .filter(rootNode => !window.initializedCaseDispositionCascadeFields.includes(rootNode))
      .forEach(rootNode => {
        window.initializedCaseDispositionCascadeFields.push(rootNode);
        new CascadedSelects(rootNode, elem_required).render(settings, selectedValues)
      });
  } else {
    rootNodes
      .filter(rootNode => !window.initializedCaseCascadeFields.includes(rootNode))
      .forEach(rootNode => {
        window.initializedCaseCascadeFields.push(rootNode);

        new CascadedSelects(rootNode, elem_required).render(settings, selectedValues)
      });
  }
}